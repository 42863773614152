import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { CoreComponent } from '../core.component';

@Component({
    selector: 'tlv-options-btns,tlv-options-btns[link]',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './options.component.html',
    styleUrl: './options.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsComponent extends CoreComponent implements AfterViewInit {

    @Input() options: string[] = [];
    @Input() checkedIndex: number = 0;
    @Input() availableValues: string[];
    @Input() urls: string[];
    @Input() allowEmptyChecked: boolean = false;
    @Input() noCheckedValue: boolean = false;
    @Output() onSelectOption: EventEmitter<string> = new EventEmitter<string>(true);


    public UID: string = String(Math.round(Math.random() * 1000000)); // NOSONAR
    private _checkedValue: string;
    private _init: boolean = true;
    private _checkedElem: HTMLElement;

    constructor(public elementRef: ElementRef) {
        super(elementRef);
    }

    @Input() set checkedValue(value: string) {
        if (!this._init) {
            this.forceCheckedValue(value);
        }
        this._checkedValue = value;
    }

    public forceCheckedValue(value: string): void {
        const item = this.getItem(value);

        if (this.allowEmptyChecked && this._checkedElem) {
            (this._checkedElem as HTMLInputElement).checked = false;
        }

        if (!!item) {
            (item as HTMLInputElement).checked = true;
        }

        this._checkedElem = item;
    }

    public getItem(value: string): HTMLElement {
        const index = this.options.indexOf(value);
        let item = null;
        if (index > -1) {
            const idItem = `#item${index}-${this.UID}`;
            item = this.elementRef.nativeElement.querySelector(idItem);
        }
        return item;
    }

    public selectOption(value: string): void {
        this._checkedValue = value;
        this._checkedElem = this.getItem(value);

        if (this.allowEmptyChecked || this._checkedElem) {
            this.onSelectOption.emit(value);
        }
    }

    public ngAfterViewInit(): void {
        const initValue = this._checkedValue || this.options[0];
        if (!this.noCheckedValue) {
            this.forceCheckedValue(initValue);
            this.onSelectOption.emit(initValue);
        }
        this._init = false;

    }
}
