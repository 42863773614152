import { Model } from '@models/model';
import { AddressModel } from '@models/cart/address.model';
import { Type } from 'class-transformer';
import bind from '../../helper/decorators/bind';

export enum CONTACT_INFORMATION_PHONE {
    FIX,
    MOBILE
}
export interface IPhone{
    createAt: Date;
    main: boolean;
    id: string;
    type: CONTACT_INFORMATION_PHONE;
    phoneNumber: string;
    contractual: boolean;
}
export interface IEmail{
    updateAt: Date;
    createAt: Date;
    main: boolean;
    id: string;
    origin: string;
    email: string;
    status: string;
}
export interface IAddress{
    updateAt: Date;
    createAt: Date;
    main: boolean;
    id: string;
    city: string;
    street: string;
    streetNumber: string;
    postalCode: string;
    countryCode: string;
    country: string;
}
export class ContactInformationsModel extends Model{

    public emails: IEmail[];
    public phones: IPhone[];
    @Type(() => AddressModel)
    public address: AddressModel[];

    constructor(data: Partial<ContactInformationsModel>){
        super(data);
    }

    get mainPhone(): IPhone{
        return this.phones.find((phone)=>phone.main);
    }

    get mainEmail(): IEmail{
        return this.emails.find((email)=>email.main);
    }

    public getMainAddress(): AddressModel{
        return [...this.address].sort(this._sortByCreationDate)[0];
    }

    @bind
    private _sortByCreationDate(a: AddressModel, b: AddressModel): number{
        return a.creationDate.getTime() - b.creationDate.getTime();
    }

}
