import { AfterViewChecked, ElementRef, Input, ViewChild, Directive } from '@angular/core';

@Directive()
export abstract class CoreComponent implements AfterViewChecked {

    protected _disabled: boolean;
    protected _loading: boolean;
    @ViewChild('elm', {static: true})
    protected element: ElementRef;

    protected classes: string[] = [
        'size-1',
        'size-2',
        'size-3',
        'size-4',
        'size-5',
        'size-6',
        'centered',
        'overlay',
        'primary',
        'success',
        'secondary',
        'link',
        'info',
        'warning',
        'danger'
    ];

    protected classesMapping = {};

    constructor(protected elementRef: ElementRef) {}

    public isDisabled(): string {
        return this._disabled ? '' : null;
    }

    public haltDisabledEvents(event: Event): void {
        // A disabled button shouldn't apply any actions
        if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    public ngAfterViewChecked(): void {
        this.turnAttrIntoClass();
    }

    public turnAttrIntoClass(): void {
        for (const cssClass of this.classes) {
            if (this.hasHostAttributes(cssClass)) {
                let attr = `${cssClass}`;

                if (cssClass.includes('size-')) {
                    this.element.nativeElement.classList.add(`is-${this.classesMapping[attr]}`);
                } else {
                    if (this.classesMapping[attr]) {
                        attr = this.classesMapping[attr];
                    }
                    this.element.nativeElement.classList.add(`is-${attr}`);
                }

            }
        }
    }

    protected hasHostAttributes(...attributes: string[]): boolean {
        if (!this.elementRef?.nativeElement) {
            return false;
        }
        return attributes.some(attribute => this.elementRef.nativeElement.hasAttribute(attribute));
    }

    @Input() set disabled(disabled: boolean) {
        this._disabled = disabled;
    }

    @Input() set loading(loading: boolean) {
        this._loading = loading;
        if (this._loading) {
            this.element.nativeElement.classList.add('is-loading');

        } else {
            this.element.nativeElement.classList.remove('is-loading');
        }

    }

}
