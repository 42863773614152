import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { OPTIONS_CONFIG } from '../config';
import { Configuration } from '../interfaces/configuration.interface';
import { Options } from '../interfaces/options.interface';
import { BasicObject } from '../interfaces/services.interface';

// @dynamic
@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    public baseConfig: Configuration;

    constructor(
        @Inject(OPTIONS_CONFIG) protected optionConfig: () => Options,
        @Inject('appConfig') public data_refconf
    ) {
        const initialConfig: Options = this.optionConfig();
        this.baseConfig = {
            environment: initialConfig.env,
            xSource: initialConfig.oauth.xSource,
            localUrl: initialConfig.oauth.local,
            resourceUrl: initialConfig.oauth.resource_url,
            salesApiVersion: initialConfig.oauth.sales_api_version
        };
    }
    public resolve(): Observable<BasicObject> {
        return of(this.data_refconf);
    }

    public getConfigurations(): BasicObject {
        return this.data_refconf;
    }

}
