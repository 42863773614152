
import { QUOTE_CONTEXTS } from '@bytel/bytel-sales';
import { PortaValidationModel } from '@models/validators/porta-validation.model';

export class PortabilityModel extends PortaValidationModel {

    public login: string;
    public expirationDate: Date;
    public lineType: QUOTE_CONTEXTS;

    constructor(data: Partial<PortabilityModel>) {
        super(data);
    }

}
