import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ProductFactory, Plan, Fai, FaimUnlimited, Sim, Product, Equipment } from '@bytel/bytel-sales';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CatalogService } from 'src/app/services/catalog.service';
import { MainCartModel } from '@models/cart/main-cart.model';
import { SalesRepository } from '@repositories/sales.repository';
import { CustomerService } from '@services/customer/customer.service';
import { ContractService } from '@services/customer/contract.service';
import { SimsByParcoursModel } from '@models/cart/sims-by-parcours';

@Injectable({
    providedIn: 'root'
})
export class SimService {

    public availableSims: SimsByParcoursModel[] = [];
    public selectedSim: string;
    public selectedSim$ = new Subject<string>();

    constructor(
        private catalogService: CatalogService,
        private salesRepository: SalesRepository,
        private readonly customerService: CustomerService,
        private readonly contractService: ContractService
    ) { }

    public setSelectedSim(gencode: string): void {
        this.selectedSim = gencode;
        this.selectedSim$.next(gencode);
    }

    public updateSimProduct(cartModel: MainCartModel, product: Product, quoteIndex?: number): Observable<Product> {
        // Update sim if Plan change
        if (product && ProductFactory.Is(product, Plan) &&
            (!ProductFactory.Is(product, Fai) || ProductFactory.Is(product, FaimUnlimited))
        ) {
            const simInCart: Sim = cartModel.getQuote(quoteIndex).getProductByType('Sim');
            return this._loadPricingOnSim(cartModel, simInCart, quoteIndex);
        }
        return of(null);
    }

    public hasNoPhysicalProductInQuote(cart: MainCartModel): boolean {
        const quoteIndex = cart.currentQuoteIndex;
        const equipment: Equipment = cart.getQuote(quoteIndex).getProductByType('Equipment');
        const plan: Plan = cart.getQuote(quoteIndex).getProductByType('Plan');
        const esim = this.hasESimInQuote(cart, quoteIndex);
        return !!plan && !!esim && !equipment;
    }

    public hasESimInCart(cart: MainCartModel): boolean {
        return !!cart.getAllProducts().find(sim => (sim.data as any)?.isESim === true);
    }

    public hasESimInQuote(cart: MainCartModel, quoteIndex: number): boolean {
        return !!cart.getQuote(quoteIndex).products.find(sim => (sim.data as any)?.isESim === true);
    }

    private _getSims(products: string[]): Observable<Sim[]> {
        return this.catalogService.getProductsByGencodes(products)
            .pipe(
                map(sims => sims as Sim[]),
                tap(sim => {
                    if (!sim) {
                        throw new Error('Sim not in Catalog');
                    }
                })
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private _loadPricingOnSim(cart: MainCartModel, simAlreadyInCart: Sim, quoteIndex: number): Observable<Sim> {
        const personId: number = +this.customerService.customer?.idPerson || 0;
        return of(null).pipe(
            mergeMap(() => this.customerService.customer?.idPerson ?
                this.contractService.getSignedContracts(this.customerService.customer?.idPerson)
                    .pipe(catchError(() => of(null))) : of(null)),
            mergeMap((contracts) => this.salesRepository.getSimsContextualises(
                cart,
                personId,
                contracts?.length ? +this.contractService.getDefaultContract(contracts)?.id : 0,
                quoteIndex
            ).pipe(
                tap((simParcours) => {
                    this.availableSims = simParcours;
                }),
                mergeMap(() => this.availableSims ? this._getSims(
                    this.availableSims.find(s=>s.sims.length > 0)?.sims?.map(s => s.gencode)).pipe(
                    map((products) => products.map((product) => this._updateSimPortabilityPrices(product, quoteIndex)))
                ) : of(null)),
                tap((sims) => {
                    if (this.availableSims?.length === 0) {
                        throw new Error('Pas de sim disponible');
                    } else {
                        this.availableSims[quoteIndex].setAssociatedSims(sims);
                    }
                }),
                map((sims) =>
                    simAlreadyInCart ? sims?.find((s) => s.gencode === simAlreadyInCart?.gencode) :
                        sims?.find((s) => s.gencode === this.availableSims[quoteIndex].preferedSim?.gencode)
                )
            ))
        );
    }

    private _updateSimPortabilityPrices(sim: Sim, quoteIndex: number): Sim {
        const portabilityPrices = this.availableSims[quoteIndex]
            .portabilityPrices
            .find((simType) => simType.gencode === sim.gencode);
        return sim.setPortabilityPrices(portabilityPrices.getOldFormat());
    }

}

