import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, mergeMap, of } from 'rxjs';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { FormDadCommissionModalComponent } from '@components/modal/form-dadcommission-modal/form-dadcommission-modal.component';
import { OpportunityStorage } from '@repositories/storages/opportunity.storage';
import { classToPlain, plainToClass } from 'class-transformer';
import { OpportunityModel } from '@models/opportunity';
import { OpportunityRepository } from '@repositories/opportunity.repository';
import { IOpportuniteOp, OpportunityDad } from '@interfaces/opportunity.interface';

@Injectable({
    providedIn: 'root'
})
export class OpportunityService {

    private _$opportunityDadSubject: Subject<OpportunityDad> = new Subject<OpportunityDad>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public $opportunityDadObs: Observable<OpportunityDad> = this._$opportunityDadSubject.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public opportunity: OpportunityModel;

    constructor(private dialogService: DialogService, private opportunityRepository: OpportunityRepository) {
        this.opportunity = this._restoreFromStorage();
    }

    public onChangeOpportunityDad(idCase, idOpportunity): void {
        this.opportunity.opportunityDad.idCase = idCase;
        this.opportunity.opportunityDad.idOpportunity = idOpportunity;
        this.save(this.opportunity);
    }

    public setOpportunitiesList(opportunitiesList: IOpportuniteOp[]): void {
        this.opportunity.opportunityDad.opportunitiesList = opportunitiesList;
        this.save(this.opportunity);
    }

    public openOpportunityDadModal(): DialogRef<any> {
        return this.dialogService.open(FormDadCommissionModalComponent, {
            closeButton: false,
            enableClose: false
        });
    }

    public getListOp(idCase): Observable<IOpportuniteOp[]> {
        return this.opportunityRepository.rechercherOpportunite(idCase).pipe(
            mergeMap(data => data.length ?
                forkJoin(data.map((idOpportuniteGP) => this.opportunityRepository.consulterOpportunite(idOpportuniteGP))) :
                of([])),
        );
    }

    public save(opportunity: OpportunityModel): void {
        this.opportunity = opportunity;
        this._$opportunityDadSubject.next(opportunity.opportunityDad);
        OpportunityStorage.setItem(OpportunityStorage.KEYS.DAD, classToPlain(opportunity));
    }

    public setIdTmpOpportunities(listIdOpportunities: string[]): void {
        this.opportunity.opportunityDad.idOpportunitiesTmp = listIdOpportunities;
        this.save(this.opportunity);
    }

    public convertTmpToUsed(): void {
        this.opportunity.opportunityDad.idOpportunitiesUsed = [...this.opportunity.opportunityDad.idOpportunitiesTmp];
        this.opportunity.opportunityDad.idOpportunitiesTmp = [];
        this.save(this.opportunity);
    }

    private _restoreFromStorage(): OpportunityModel {
        let res: OpportunityModel = new OpportunityModel({
            idCase: '',
            idOpportunity: '',
            idOpportunitiesUsed: [],
            idOpportunitiesTmp: [],
            opportunitiesList: []
        });
        const dataFromStorage = OpportunityStorage.getItem(OpportunityStorage.KEYS.DAD);
        if (dataFromStorage) {
            res = plainToClass(OpportunityModel, dataFromStorage);
        }
        this.save(res);
        return res;
    }

}
