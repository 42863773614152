export interface PortabilityPricingSim {
    aUnePortabilite: PricingSim;
    nAPasDePortabilite: PricingSim;
}

export enum PriceTypeEnum {
    IMMEDIAT = 'IMMEDIAT',
    MENSUEL = 'MENSUEL',
    DIFFERE = 'DIFFERE',
    AUTRE = 'AUTRE'
}

interface IPortabilityPricingSimCompatible {
    gencode: string;
    prefere: boolean;
    prix: number;
    terminalCompatible: boolean;
    type: string;
    typePrix: PriceTypeEnum;
}
export interface IPortabilitySimPricing {
    parcours: {
        estCourant: boolean;
        id: string;
        simsCompatibles: IPortabilityPricingSimCompatible[];
    }[];
}

export interface IPortabilityPricingSim {
    parcours: {
        idVirtuel: number;
        produits: {
            idVirtuel: number;
            gencode: string;
            prix: ISimPrice;
        }[];
    }[];
}

export interface ISimPrice {
    type: number;
    price: number;
}

export interface PricingSim {
    price: number;
    type?: number;
}
export interface ProductApiResult {
    idParcours: number;
    idProduit: number;
    gencode: string;
    idUnique: string;
    prix: number;
    sku: string;
    type_id: string;
    scanCode: string;
    schemeId: string;
}

