<p #elm class="price" [ngClass]="{'is-main-price': isMainPrice, 'is-inlined': isInlined, 'has-description': hasIcon, 'has-text-grey-dark': isDiscountPrice, 'is-greyed': isDiscountPrice,'is-striked':isDiscountPrice}" >
    <span class="main"><span *ngIf="isAdditionalPrice">+</span>{{mainPrice}}</span>

    <span class="price-details" >
        <span class="cents">
            <span>€{{centimePrice}}
                <span *ngIf="isWithoutTax && isInlined">HT</span>
                <sup *ngIf="(isWithoutTax && !isInlined) || asterix">{{(isWithoutTax && !isInlined) ? 'HT' : ''}} {{asterix ? '('+asterix+')':''}}</sup></span>
            <span class="icon" *ngIf="hasIcon">
              <span [ngClass]="iconClassNames" (click)="clickIcon()"></span>
            </span>
        </span>
        <span *ngIf="hasPeriod" class="period">/mois</span>
    </span>
</p>