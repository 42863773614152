import { FaiTechnoModel } from '@models/fai/fai-techno.model';
import { Type } from 'class-transformer';
import { IFaiApiRawResultsData, IFaiEligibilityResultat, IFaiResultat } from '@interfaces/api/fai.interface';
import { FaiAddressModel } from '@models/fai/fai-address';

export class FaiEligibilityModel{

    public adsl: FaiTechnoModel;
    public vdsl: FaiTechnoModel;
    public thd: FaiTechnoModel;
    public ftth: FaiTechnoModel;
    public box_4g: FaiTechnoModel;
    public box_5g: FaiTechnoModel;
    @Type(() => FaiAddressModel)
    public installationAddress: FaiAddressModel;
    public isCDL: boolean;
    public webshopData: IFaiResultat<IFaiEligibilityResultat> ;
    public rawApiData: IFaiApiRawResultsData;

    constructor(data: Partial<FaiEligibilityModel>) {
        Object.assign(this,data);
    }

    public getOCs(): string[]{
        return [].concat(
            this.adsl.offres,
            this.vdsl.offres,
            this.ftth.offres,
            this.box_4g?.offres,
            this.box_5g?.offres
        ).filter(oc => !!oc);
    }


}
