<div data-cy="appointment-modal-skip">
    <div class="columns is-multiline is-marginless is-centered has-text-centered">
        <div class="column is-narrow">
            <span class="icon has-text-warning is-huge">
                <i class="tri-exclamation-circle"></i>
            </span>
            <p class="has-text-weight-semibold is-size-5">Commande en doublons !</p>
        </div>

        <div class="column is-12">
            <div>
                <p>Une commande est déjà en cours pour ce client <span class="has-text-weight-semibold">{{ref.data.orderId}}</span> à cette adresse.</p>
            </div>
        </div>
        <div class="column is-12">
            <div>
                <a href="https://bouyguestelecom--c.vf.force.com/apex/KDT_Interview_VFP?id=a0C0800002k9qpf" target="_blank" rel="noopener">Quelle est la demande du client ?</a>
            </div>
        </div>

        <div class="column is-6">
            <button class="is-primary button is-fullwidth" data-cy="cancel-order-modal-cta" (click)="ref.close({continue: false})">Terminer session</button>
        </div>
    </div>
</div>
