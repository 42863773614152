import { Directive, OnInit, Input, HostListener } from '@angular/core';
import { PrismeLoggerService } from '@services/prisme-logger.service';

export enum ACTIONS {
    DEFAULT = '',
    CLICK = 'click'
}

@Directive({
    selector: '[tlvPrismeLogger]'
})
export class PrismeLoggerDirective implements OnInit {

    @Input() tlvPrismeLogger = '';
    @Input() action = ACTIONS.DEFAULT;
    public readonly ACTIONS = ACTIONS;

    constructor(protected prismeLoggerService: PrismeLoggerService) { }

    public ngOnInit(): void{
        if (!this.action) {
            this.sendDataToPrisme();
        }
    }

    @HostListener('click', ['$event']) onClick(): void {
        if (this.action === ACTIONS.CLICK) {
            this.sendDataToPrisme();
        }
    }

    private sendDataToPrisme(): void {
        this.prismeLoggerService.sendDataToPrisme(this.tlvPrismeLogger);
    }
}
