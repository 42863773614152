export interface IProspectGP {
    typeClient: ProspectGP.TypeClientEnum;
    login: string;
    motPasse?: string;
    creerSession?: boolean;
    civilite?: ProspectGP.CiviliteEnum;
    nom?: string;
    prenom?: string;
    departementNaissance?: string;
    dateNaissance?: string;
    numeroTel?: string;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ProspectGP {
    export type TypeClientEnum = 'PRO' | 'GP' | 'CLIENT';
    export const TypeClientEnum = {
        PRO: 'PRO' as TypeClientEnum,
        GP: 'GP' as TypeClientEnum,
        CLIENT: 'CLIENT' as TypeClientEnum
    };
    export type CiviliteEnum = 'MME' | 'M';
    export const CiviliteEnum = {
        MME: 'MME' as CiviliteEnum,
        M: 'M.' as CiviliteEnum
    };
}
