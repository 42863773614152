import { Product } from '@bytel/bytel-sales';
import { Model } from '@models/model';

export class CategoryModel extends Model {
    id: string;
    name: string;
    description: string;
    code: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    products: string[];
    subtitle: string;
    sliderPosition: 0;
    image: string;
    imageMobile: string;
    url: string;
    children: CategoryModel[];
    order: number;
    hidden: boolean;

    constructor(data: Partial<CategoryModel>) {
        super(data);
    }
}
export class CategoryWithProductModel<T extends Product = Product> {
    id: string;
    name: string;
    description: string;
    code: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    products: T[];
    productsGencode: string[];
    subtitle: string;
    sliderPosition: 0;
    image: string;
    imageMobile: string;
    url: string;
    children: CategoryWithProductModel<T>[];
    order: number;
    hidden: boolean;

    constructor(data: CategoryModel,products: T[],children: CategoryWithProductModel<T>[]) {
        Object.assign(this,data);
        this.children = children || [];
        this.productsGencode = products.reduce((acc,product)=>[
            ...acc,
            product.gencode
        ],[]);
        this.products = products;
    }
}
