import { Injectable } from '@angular/core';
import { JsonProduct, IProductRepositoryInterface, Product } from '@bytel/bytel-sales';
import { DisplayOptionModel } from '@models/cart/display-option.model';
import { EquipmentDetailsModel } from '@models/equipment-details.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductRepository } from './product.repository';
@Injectable({ providedIn: 'root' })
export class ProductDetailsRepository implements IProductRepositoryInterface {

    public readonly MODELS_TYPES: { [index: string]: any } = {
        ['box']: this._createEquipmentDetailsModel,
        ['phone']: this._createEquipmentDetailsModel,
        ['phone_simple']: this._createEquipmentDetailsModel,
        ['option']: this._createOptionDetailsModel,
        ['option_salable']: this._createOptionDetailsModel,
        ['offer_partner']: this._createOptionDetailsModel
    };

    constructor(
        private productRepository: ProductRepository
    ) {}

    public getProductByGencode(gencode: string): Observable<Product> {
        return this.productRepository.getProductByGencode(gencode);
    }

    public getProductWithDetails<T extends Product>(gencode: string): Observable<T> {
        return this.productRepository.getProductWithDetails(gencode)
            .pipe(
                map((p) => this._getProductDetailsInstance(p))
            );
    }

    private _getProductDetailsInstance<T extends Product>(product: JsonProduct): T {
        return this.MODELS_TYPES[product.type_id] ?
            this.MODELS_TYPES[product.type_id](product) :
            this._createEquipmentDetailsModel(product);
    }

    private _createOptionDetailsModel(product: JsonProduct): DisplayOptionModel {
        return new DisplayOptionModel(
            product,
            true,
            // Can't work as such
            product.upSellsProducts.find(upSell=>upSell.gencode === product.gencode)?.position
        );
    }

    private _createEquipmentDetailsModel(product: JsonProduct): EquipmentDetailsModel {
        return new EquipmentDetailsModel({
            battery: product.battery_capacity,
            auto_focus: product.auto_focus,
            battery_capacity: product?.battery_capacity,
            battery_removable: product.battery_removable,
            battery_standby_time: product.battery_standby_time,
            battery_talk_time: product.battery_talk_time,
            capacity_available: product.capacity_available,
            double_camera: product.double_camera,
            os_name: product.os_code,
            os_version: product.os_version,
            limbs_sar: product.limbs_sar,
            screen_size: product.diagonal,
            resolution_sensor: product.resolution_sensor,
            processor_core: product.processor_core,
            multiple_sim: product.sim_multiple,
            repairNote: parseFloat(product.note_reparation),
            repairNotePdf: product.note_reparation_pdf,
            processor_speed: product.processor_speed,
            crossSellsProducts: product.crossSellsProducts,
            weight: product.weight,
            bonus_recovery: product.bonus_recovery,
            recovery_from_date: product.recovery_from_date,
            recovery_to_date: product.recovery_to_date,
            length: product.length,
            height: product.height,
            width: product.width,
            wifi: product.wifi,
            resolution_width: product.resolution_width,
            resolution_height: product.resolution_height,
        });
    }

}
