import { Model } from '@models/model';
import { USER_TITLES } from '../../constants/customer';
import { AddressModel } from '@models/cart/address.model';

export class CompanyModel extends Model{

    public socialReason: string;
    public creationDate: Date;
    public legalForm: string;
    public codeApeNaf: string;
    public socialCapital: string;
    public siren: string;
    public legalRepresentative: {
        gender: USER_TITLES;
        birthDate: Date;
        firstName: string;
        lastName: string;
    };
    public address: AddressModel;

    constructor(data: Partial<CompanyModel>){
        super(data);
    }

}
