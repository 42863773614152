
import { Model } from '@models/model';

export class FaiLoginsAndVoipsModel extends Model {

    public cartId: string;
    public voipNumbers: {voipNumber: string; type: string}[];
    public logins: string[];

    constructor(data: Partial<FaiLoginsAndVoipsModel>) {
        super(data);
    }

    public get voipNumbersList(): string[] {
        return this.voipNumbers.map(vNumber => vNumber.voipNumber.toString());
    }
}
