import { AddressModel } from '@models/cart/address.model';
import { Model } from '@models/model';
import { Type } from 'class-transformer';

export class OrderDeliveryQuoteInfoModel extends Model {

    @Type(()=>AddressModel)
    public deliveryAddress: AddressModel;
    public estimatedDeliveryDate: Date;
    public lateEstimatedDeliveryDate: Date;
    public type: string;
    public equipment: string;

    constructor(data: Partial<OrderDeliveryQuoteInfoModel>) {
        super(data);
    }
}
