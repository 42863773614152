<ng-template #ngContent>
    <ng-content></ng-content>
</ng-template>

<ng-container>
    <a #elm [ngClass]="{'is-hidden': isButton}" [attr.disabled]="isDisabled()" [attr.tabindex]="" [attr.href]="href" [style.backgroundColor]="color">
        <span *ngIf="icon" [ngClass]="'icon is-' + iconSize">
            <i [ngClass]="icon"></i>
        </span>
        <ng-container *ngIf="!isButton">
            <ng-container *ngTemplateOutlet="ngContent"></ng-container>
        </ng-container>
    </a>
    <button #btlElm [ngClass]="{'is-hidden': !isButton}" [attr.disabled]="isDisabled()" [attr.tabindex]="" [attr.href]="href" [style.backgroundColor]="color">
        <span *ngIf="icon" [ngClass]="'icon is-' + iconSize">
            <i *ngIf="icon" [ngClass]="icon"></i>
        </span>
        <ng-container *ngIf="isButton">
            <ng-container *ngTemplateOutlet="ngContent"></ng-container>
        </ng-container>
    </button>
</ng-container>
