<div [bytelCy]="'divstore-'+store.id" class="store" [ngClass]="{'is-last': isLast, 'is-selected': isSelected, 'is-standalone': isStandAlone}">
    <p class="has-text-weight-medium is-marginless">{{store.name}}</p>
    <p class="is-size-7" bytelCsMask>
        <span><span *ngIf="store?.street_number">{{store?.street_number}} </span>{{store.street}} {{store.postalCode}} {{store.city}}</span>
    </p>
    <div class="columns is-marginless">
        <div class="column is-paddingless">
            <p class="is-size-7 is-marginless">
                <span class="icon-and-text">
                    <span class="icon is-small is-marginless">
                        <span bytelSvg [class.has-text-info]="isSelected" [name]="isSelected ? store?.icons.SELECTED.iconName : store?.icons.DEFAULT.iconName" title></span>
                    </span>
                    <span>{{store.distanceFromAdress}} <abbr title="kilomètres">km</abbr></span>
                </span>
            </p>
        </div>
        <div class="column is-narrow is-paddingless">
            <a [bytelCy]="'btnstore-'+store.id" class="button is-tertiary" [ngClass]="{'is-hidden': (isStandAlone || isSelected)}" (click)="selectStore()">
                {{ store.deliveryType === 'CLICK_COLLECT' ? 'Choisir cette boutique' : 'Choisir ce point relais' }}
            </a>
            <div [ngClass]="{'is-hidden': (isStandAlone || !isSelected)}" class="icon-and-text is-justified-center is-size-7 has-text-weight-bold">
                <span class="icon is-small has-text-white"><i class="tri-check-circle"></i></span>
                {{ store.deliveryType === 'CLICK_COLLECT' ? 'Boutique choisie' : 'Point relais choisi' }}
            </div>
        </div>
    </div>
    <a (click)="showHours = !showHours" class="has-cursor toggle-store-openings" [ngClass]="{'has-text-white': isSelected}">
        <span class="icon-and-text">
            <span>Voir les horaires d'ouverture</span>
            <span class="icon is-small" [ngClass]="{'rotate-up': showHours}">
                <i class="tri-arrow-down"></i>
            </span>
        </span>
    </a>
    <div class="hours-infos" [ngClass]="{'is-expanded': showHours}">
        <p *ngFor="let hour of store.hours.weekly | keyvalue" class="text" [ngClass]="{'has-text-weight-semibold': +hour.key === currentDay, 'is-3': isMobileMQ, 'has-text-white': isSelected && isMobileMQ}">
            <span>{{hour.value.dayName}}</span>
            <span [ngClass]="{'has-text-weight-medium': !hour.value.dayOpeningHours}" class="is-pulled-right">{{hour.value.dayOpeningHours}}</span>
        </p>
    </div>

</div>
