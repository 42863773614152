import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CartModel, Delivery, Equipment, Product, ProductFactory, QuoteModel } from '@bytel/bytel-sales';
import { IStoreCheckout } from '@interfaces/store-checkout.interface';
import { AddressModel } from '@models/cart/address.model';
import { DeliveryAppointmentModel } from '@models/cart/delivery-appointment.model';
import { DeliveryInfoModel } from '@models/cart/delivery-info.model';
import { MainCartModel } from '@models/cart/main-cart.model';
import { HotToastService } from '@ngneat/hot-toast';
import { DeliveryRepository } from '@repositories/delivery.repository';
import { SalesRepository } from '@repositories/sales.repository';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { AddressService } from '@services/checkout/address.service';
import { CustomerService } from '@services/customer/customer.service';
import { SharedService } from '@services/shared.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SHIPPING_MODES } from '../../constants/delivery';
import {IconsulterPolitiqueTarifairePaniersApresVente} from '@interfaces/delivery.inteface';
import {SalesForceRepository} from '@repositories/sales-force.repository';


@Injectable({
    providedIn: 'root'
})
export class DeliveryService {

    public selectedStore: IStoreCheckout;
    public selectedAppointment: DeliveryAppointmentModel;
    public errorShippingAddress$: Observable<string>;
    private _errorShippingAddressSubject: Subject<string> = new Subject<string>();
    private _currentDeliveryInfo: DeliveryInfoModel;

    constructor(private deliveryRepository: DeliveryRepository,
                private readonly addressService: AddressService,
                private readonly customerService: CustomerService,
                private readonly toastService: HotToastService,
                private readonly salesRepository: SalesRepository,
                private readonly salesForceRepository: SalesForceRepository) {
        this.errorShippingAddress$ = this._errorShippingAddressSubject.asObservable();
        this.loadStoreAndSlot();
        this._currentDeliveryInfo = this.deliveryRepository.getSelected();
    }

    public setDeliveryAndGetProduct(deliveryInfo: DeliveryInfoModel): Delivery {
        this._currentDeliveryInfo = deliveryInfo;
        this.deliveryRepository.saveSelected(deliveryInfo);
        return deliveryInfo.product;
    }

    public getDelivery(): DeliveryInfoModel {
        return this._currentDeliveryInfo;
    }

    public setStore(store: IStoreCheckout): void{
        CheckoutStorage.setItem(CheckoutStorage.KEYS.SHIPPING_STORE,store);
        this.selectedStore = store;
    }

    public setSlot(slot: DeliveryAppointmentModel): void{
        CheckoutStorage.setItem(CheckoutStorage.KEYS.SHIPPING_APPOINTMENT,slot);
        this.selectedAppointment = slot;
    }

    public removeDelivery(cart: MainCartModel,product: Product, quoteIndex: number): Delivery {
        if (!ProductFactory.Is(product, Delivery)) {
            this._currentDeliveryInfo = null;
            this.deliveryRepository.deleteSelected();
            const deliveryProduct = cart.getQuote(quoteIndex).getProductByType(Delivery);
            if (deliveryProduct) {
                return deliveryProduct;
            }
        }
    }

    public consulterPolitiqueTarifairePaniersApresVente(): Observable<IconsulterPolitiqueTarifairePaniersApresVente> {
        const id = this.salesForceRepository.getData()?.order?.idPanierRetours;
        if (id) {
            return this.deliveryRepository.consulterPolitiqueTarifairePaniersApresVente(id);
        }
        return of(null);
    }

    public getDeliveryAvailable(cart: MainCartModel, address: AddressModel): Observable<DeliveryInfoModel[]>{
        return this.deliveryRepository.getDeliveryAvailable(cart, address).pipe(
            tap(()=>{
                this._errorShippingAddressSubject.next(null);
            }),
            catchError((err) => {
                this._errorShippingAddressSubject.next(err?.error?.error_description);
                return throwError(err);
            })
        );
    }

    public generateStoreFromDeliveryAddress(address: AddressModel): IStoreCheckout {
        return {
            ...this.deliveryRepository.generateStoreFromDeliveryAddress(address),
            rvc: null,
            reseauLivraison: null
        };
    }

    public getStoreForDelivery(prodcut: Delivery,latitude: number,longitude: number,cart: CartModel): Observable<IStoreCheckout[]>{
        switch (prodcut.gencode){
            case SHIPPING_MODES.CLICK_AND_COLLECT:
                return this.deliveryRepository.getClickAndCollect(longitude,latitude,cart.getQuote().getProductByType(Equipment));
            case SHIPPING_MODES.RELAIS_COLIS :
                return this.deliveryRepository.getRelaisColis(longitude,latitude);
            case SHIPPING_MODES.RELAIS_EXPRESS :
                return this.deliveryRepository.getPointRelais(longitude,latitude);
            default:
                return of(null);
        }
    }

    public pushDelivery(mainCart: MainCartModel, quote: QuoteModel,product: Delivery): Observable<void>{
        let address;
        const isDeliveryStore = [
            SHIPPING_MODES.CLICK_AND_COLLECT,
            SHIPPING_MODES.RELAIS_COLIS,
            SHIPPING_MODES.RELAIS_EXPRESS
        ].includes(product.gencode as SHIPPING_MODES);
        if (this.selectedStore && isDeliveryStore) {
            address = new AddressModel({
                postalCode: this.selectedStore.postalCode.toString(),
                city: this.selectedStore.city,
                streetNumber: this.selectedStore.street_number?.toString(),
                street: this.selectedStore.street,
                valid: false
            });
        } else {
            address = this.addressService.currentShippingAddress || this.addressService.currentBillingAddress;
        }
        return this.salesRepository.pushDelivery(
            mainCart,
            quote,
            address,
            this.customerService.customer,
            product,
            this.selectedStore,
            this.selectedAppointment).pipe(
            catchError((err: HttpErrorResponse) => {
                this.toastService.error('Erreur: ' + (err?.error?.error_description || SharedService.DEFAULT_API_ERROR_MESSAGE));
                return throwError(err);
            }),
        );
    }

    public clear(): void {
        this.selectedStore = null;
        this.selectedAppointment = null;
        this.deliveryRepository.deleteSelected();
    }

    public loadStoreAndSlot(): void {
        const storedStore = CheckoutStorage.getItem(CheckoutStorage.KEYS.SHIPPING_STORE);
        const storedAppointment = CheckoutStorage.getItem(CheckoutStorage.KEYS.SHIPPING_APPOINTMENT);
        if (storedStore){
            this.selectedStore = storedStore;
        }
        if (storedAppointment){
            this.selectedAppointment = storedAppointment;
        }
    }

}
