import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';

type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

@Directive({
    selector: '[tlvFormClass]'
})
export class FormClassDirective implements OnInit {

    @Input() tlvFormClassConfig: { error: string; success: string } = {success: 'is-success', error: 'is-danger'};
    @Input() tlvFormClassErrorControl: AbstractControl;

    private _touchedChanges$ = new Subject<boolean>();

    constructor(public el: ElementRef) {}

    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const prevMarkAsTouched = this.tlvFormClassErrorControl.markAsTouched;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const prevMarkAsUntouched = this.tlvFormClassErrorControl.markAsUntouched;
        this.tlvFormClassErrorControl.markAsTouched = (...args: ArgumentsType<AbstractControl['markAsTouched']>): void => {
            prevMarkAsTouched.bind(this.tlvFormClassErrorControl)(...args);
            this._touchedChanges$.next(true);
        };
        this.tlvFormClassErrorControl.markAsUntouched = (...args: ArgumentsType<AbstractControl['markAsUntouched']>): void => {
            prevMarkAsUntouched.bind(this.tlvFormClassErrorControl)(...args);
            this._touchedChanges$.next(false);
        };

        merge(
            this.tlvFormClassErrorControl.statusChanges,
            this._touchedChanges$
        ).subscribe(() => {
            this.el.nativeElement.classList.remove(this.tlvFormClassConfig.error);
            this.el.nativeElement.classList.remove(this.tlvFormClassConfig.success);
            if (!this.tlvFormClassErrorControl.disabled && this.tlvFormClassErrorControl.touched){
                if (this.tlvFormClassErrorControl.invalid){
                    this.el.nativeElement.classList.add(this.tlvFormClassConfig.error);
                } else {
                    this.el.nativeElement.classList.add(this.tlvFormClassConfig.success);
                }
            }
        });
        this._touchedChanges$.next(this.tlvFormClassErrorControl.touched);
    }

}
