import { Model } from '@models/model';

export enum SimsTypes {
    sim = 'PHYSICAL',
    qr_esim = 'VIRTUAL'
}

export class SimsTypesModel extends Model {

    public gencode: string;
    public isPrefered: boolean;
    public type: string;
    public name: string;
    public isCompatibleWithDevice: boolean;

    constructor(data: Partial<SimsTypesModel>) {
        super(data);
        this.name = this.isPhysical() ? 'SIM' : 'eSIM';
    }

    public isPhysical(): boolean{
        return SimsTypes[this.type] === SimsTypes.sim;
    }

}
