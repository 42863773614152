import { CustomerModel } from '@models/customer/customer.model';
import { ContactInformationsModel } from '@models/customer/contact-informations.model';
import { AddressModel } from '@models/cart/address.model';
import { Type } from 'class-transformer';


export class CustomerDetailsModel extends CustomerModel {

    public email: string;
    public phone: string;
    @Type(() => AddressModel)
    public address: AddressModel;

    constructor(customer: Partial<CustomerModel>,public contactInformation?: ContactInformationsModel){
        super(customer);
        if (contactInformation){
            this.phone = contactInformation.mainPhone?.phoneNumber;
            this.email = contactInformation.mainEmail?.email;
            this.address = contactInformation.getMainAddress();
        }
    }

    public equal(customer: CustomerDetailsModel): boolean{
        return this.birthDate.getTime() === customer.birthDate.getTime() &&
            this.birthDepartment === customer.birthDepartment &&
            this.email === customer.email &&
            this.firstname === customer.firstname &&
            this.lastname === customer.lastname &&
            this.idPerson === customer.idPerson &&
            this.gender === customer.gender &&
            this.phone === customer.phone;
    }

}
