export enum SHIPPING_MODES {
    CLICK_AND_COLLECT = 'CLICK_COLLECT',
    RELAIS_EXPRESS = 'POINT_RELAIS_EXPRESS',
    RELAIS_COLIS = 'POINT_RELAIS',
    LA_POSTE = 'LA_POSTE',
    CHRONOPOST = 'CHRONOPOST',
    INSTALLER = 'INSTALLATEUR',
    DIRECT = 'LIVRAISON_DIRECTE',
    LIVRAISON_RDV = 'LIVRAISON_RDV'
}

export enum STORE_TYPE {
    RELAIS = 'Relais Pickup',
    CONSIGNE = 'Consigne Pickup'
}
