export interface IFundingResponse {
    modesDeFinancement: IModesDeFinancement[];
    idPanier:           string;
    parcours:           IParcours[];
}

export interface IModesDeFinancement {
    libelle:                  string;
    modesDePaiement?:         IModesDePaiement[];
    propositionsCommerciales: IPropositionsCommerciale[];
    type:                     TYPE_FINANCEMENT;
}

export interface IModesDePaiement {
    type: MODES_DE_PAIEMENT;
}

export interface IPropositionsCommerciale {
    apportInitial:            number;
    montantReelAbsolu:        number;
    coutTotalFinancement:     number;
    montantAFinancer:         number;
    montantInterets:          number;
    montantMensualite:        number;
    nbrEcheances:             number;
    tAEG:                     number;
    dernierMontantMensualite: number;
    dateFinancement:          string;
    premiereDateMensualite:   string;
}

export interface IParcours {
    idParcours: string;
    produits:   IProduit[];
}

export interface IProduit {
    idProduit:                  string;
    gencode:                    string;
    modesDeFinancementPanier:   string[];
    modesDeFinancementProduits: IModesDeFinancement[];
}

export enum MODES_DE_PAIEMENT {
    CB = 'CB',
    PAYPAL = 'PAYPAL',
    SANS_PAIEMENT = 'SANS_PAIEMENT',
    PAYLIB = 'PAYLIB',
    CREDIT_X3 = 'CREDIT_X3',
    CREDIT_MENSUEL = 'CREDIT_MENSUEL',
    HYBRIDE = 'HYBRIDE',
    VIREMENT_ENTRANT = 'VIREMENT_ENTRANT'
}

export enum TYPE_FINANCEMENT {
    EDP = 'EDP',
    COMPTANT = 'COMPTANT',
    COFIDIS_X3 = 'COFIDIS_X3',
    COFIDIS_X12 = 'COFIDIS_X12',
    COFIDIS_X24 = 'COFIDIS_X24',
    MULTI_PRODUIT = 'MULTI_PRODUIT',
    YOUNITED = ' YOUNITED'
}

export interface IFundingResquest {
    modeDeFinancement: string;
    modeDePaiement:    string;
    urlDeRappel:       string;
    parcours?:         IParcourRequest[];
}

export interface IParcourRequest {
    idParcours: string;
    produits:   IProduitRequest[];
}

export interface IProduitRequest {
    idProduit:         string;
    modeDeFinancement: string;
}
