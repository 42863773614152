import { AddressModel } from '@models/cart/address.model';
import { Type } from 'class-transformer';
import { Model } from '@models/model';
import { DeliveryInfoModel } from '@models/cart/delivery-info.model';
import { IStoreCheckout } from '@interfaces/store-checkout.interface';
import { OrderDeliveryQuoteInfoModel } from './order-delivery-quote-info.model';

export class OrderDeliveryInfoModel extends Model {

    @Type(()=>AddressModel)
    public billing: AddressModel;
    @Type(()=>AddressModel)
    public shipping: AddressModel;
    @Type(()=>DeliveryInfoModel)
    public deliveryInfos: DeliveryInfoModel;
    @Type(()=>OrderDeliveryQuoteInfoModel)
    public mobile?: OrderDeliveryQuoteInfoModel;
    @Type(()=>OrderDeliveryQuoteInfoModel)
    public fai?: OrderDeliveryQuoteInfoModel;
    public store?: IStoreCheckout;

    constructor(data: Partial<OrderDeliveryInfoModel>) {
        super(data);
    }
}
