export class FaiPtoModel{

    public batiment: string;
    public escalier: string;
    public etage: string;
    public ptos: string[];
    public presencePto?: boolean;
    public currentPto: string;
    public idPtoObligatoire: boolean;
    public oi: string;

    constructor(data: Partial<FaiPtoModel>) {
        Object.assign(this,data);
        this.presencePto = !!this.currentPto;
    }
}
