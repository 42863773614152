import { Injectable } from '@angular/core';
import { AddressRepository } from '@repositories/address.repository';
import { AddressModel } from '@models/cart/address.model';
import { Observable, of } from 'rxjs';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { map, mergeMap, tap } from 'rxjs/operators';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { ContractService } from '@services/customer/contract.service';
import { SalesForceService } from '@services/salesforce.service';
import { SignedContractModel } from '@models/customer/signedContract.model';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    public get currentBillingAddress(): AddressModel{
        return this._currentBillingAddress;
    }
    public set currentBillingAddress(val: AddressModel){
        this._currentBillingAddress = val;
        this.addressRepository.setBilling(val);
    }
    public get currentShippingAddress(): AddressModel{
        return this._currentShippingAddress;
    }
    public set currentShippingAddress(val: AddressModel){
        this._currentShippingAddress = val;
        this.addressRepository.setShipping(val);
    }
    public billingEditable: boolean = true;
    public defaultAddress: AddressModel;

    private _currentShippingAddress: AddressModel = this.addressRepository.getShipping();
    private _currentBillingAddress: AddressModel = this.addressRepository.getBilling();

    constructor(private addressRepository: AddressRepository,
                private contractService: ContractService,
                private salesForceService: SalesForceService,
                private faiEligibilityService: FaiEligibilityService,
                private customerService: CustomerService) {}

    public resolve(): Observable<AddressModel> | AddressModel {
        this.billingEditable = this._isBillingEditable();
        return this.currentBillingAddress || this._getCustomerDefaultAddress().pipe(
            map((address)=>
                address ?? this.addressRepository.getBilling()
            ),
            tap((address)=>this._currentBillingAddress = address)
        );
    }

    public loadLocationInAddress(address: AddressModel): Observable<AddressModel>{
        return this.addressRepository.loadLocationInAddress(address);
    }

    public getDefaultBillingAddress(personId: string): Observable<AddressModel>{
        return this.contractService.getSignedContracts(personId).pipe(
            map(this.contractService.getDefaultContract),
            mergeMap((contract: SignedContractModel)=>this.contractService.getBillingAddresses(personId,contract.billingAccountId)),
        );
    }

    private _isBillingEditable(): boolean{
        if (this.customerService.customer instanceof CustomerProDetailsModel ||
            this.customerService.customer.isClient){
            return false;
        }
        if (this.customerService.customer.address) {
            return this.customerService.customer.isUpdatable;
        }
        return true;
    }

    private _getCustomerDefaultAddress(): Observable<AddressModel>{
        if (this.salesForceService.prefilledInfo?.order) {
            return of(this.salesForceService.prefilledInfo.order.cart.delivery.billing);
        }
        if (this.customerService.customer instanceof CustomerProDetailsModel){
            return of(this.customerService.customer.address);
        }
        if (this.customerService.customer.isClient){
            return this.getDefaultBillingAddress(this.customerService.customer.idPerson);
        } else if (this.customerService.customer.address) {
            this.billingEditable = this.customerService.customer.isUpdatable;
            return of(this.customerService.customer.address);
        }
        if (this.faiEligibilityService.currentCart?.eligibility?.isCDL){
            return of(this.faiEligibilityService.currentCart.searchAddress.getAddressModel());
        }
        if (this.faiEligibilityService.currentCart?.eligibility?.installationAddress){
            return of(this.faiEligibilityService.currentCart.eligibility.installationAddress.getAddressModel());
        }
        if (this.salesForceService.prefilledInfo.address){
            return of(this.salesForceService.prefilledInfo.address);
        }
        return of(null);
    }

}

