import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import { ContexteOperateur, CONTRACT_STATUS } from '@interfaces/contract.interface';
import { Observable } from 'rxjs';
import { ListeContrat } from '@interfaces/api/signed-contracts/listeContrat';
import { map, mergeMap } from 'rxjs/operators';
import bind from '../helper/decorators/bind';
import { MARKET_LINE, SignedContractModel } from '@models/customer/signedContract.model';
import { Contrat } from '@interfaces/api/signed-contracts/contrat';
import { BillingAddressModel } from '@models/cart/billing-address.model';
import { BillingAccountModel } from '@models/customer/billing-account.model';
import { ConsulterComptesFacturationPersonne } from '@interfaces/api/billing/consulterComptesFacturationPersonneListeCompteFacturation';
import {
    ConsulterComptesFacturationPersonneCompteFacturation
} from '@interfaces/api/billing/consulterComptesFacturationPersonneCompteFacturation';
import { Adresses } from '@interfaces/api/adresses/adresses';

@Injectable({
    providedIn: 'root'
})
export class ContractRepository {

    constructor(private oauthService: Oauth2ResourcesService) { }

    public consulterContexteOperateur(idPerson: string): Observable<ContexteOperateur> {
        return this.oauthService
            .personnes(idPerson)
            .consulterContexteOperateur()
            .get();
    }

    public getSignedContracts(idPerson: string): Observable<SignedContractModel[]>{
        return this.oauthService.personnes(idPerson).contratsSignes().get<ListeContrat>().pipe(
            map((data)=>data.items.map(this._createContractModel))
        );
    }

    public getBillingAddresses(personId: string,billingAccountId: string): Observable<BillingAddressModel>{
        return this.oauthService.comptesFacturation(billingAccountId).get().pipe(
            mergeMap((billingAccount)=>this.oauthService.setUrl(billingAccount._links?.adresseFacturation?.href).get()),
            map(this._createBillingAddressModel)
        );
    }

    public getBillingAccount(personId: string): Observable<BillingAccountModel[]>{
        return this.oauthService.personnes(personId).comptesFacturation().get<ConsulterComptesFacturationPersonne>().pipe(
            map((data)=>data.items.map(this._createBillingAccount))
        );
    }

    @bind
    public _createBillingAccount(data: ConsulterComptesFacturationPersonneCompteFacturation): BillingAccountModel{
        return new BillingAccountModel(data);
    }

    @bind
    private _createContractModel(data: Contrat): SignedContractModel{
        return new SignedContractModel({
            marketLine : Object.values(MARKET_LINE).find((line)=>data.ligneMarche === line),
            creationDate : new Date(data.dateCreation),
            id: data.id,
            status: this._getContractStatus(data),
            billingAccountId: data._links.compteFacturation.href.split('/').pop()
        });
    }

    private _getContractStatus(data: Contrat): CONTRACT_STATUS{
        switch (data.statut){
            case 'ACTIF':
                return CONTRACT_STATUS.ACTIVE;
            case 'RESILIE':
                return CONTRACT_STATUS.DEACTIVATED;
            case 'SUSPENDU':
                return CONTRACT_STATUS.SUSPENDED;
        }
    }

    @bind
    private _createBillingAddressModel(data: Adresses): BillingAddressModel{
        return new BillingAddressModel({
            postalCode: data.codePostal,
            city: data.ville,
            street: data.rue,
            streetNumber: data.numero,
            complement: data.complementAdresse1,
            lastUpdate: new Date(data.dateMiseAJour)
        });
    }

}
