import { Model } from '@models/model';
import { PRICE_TYPES } from '@bytel/bytel-sales';
import { POLITIQUE_TARIFAIRE } from '@interfaces/delivery.inteface';

export class OrderPaymentInfoModel extends Model {

    public iban: string;
    public initialFmsPaymentType: PRICE_TYPES;
    public fmsPaymentType: string;
    public amount: number;
    public method: string;
    public politiqueTarifaire: POLITIQUE_TARIFAIRE;

    constructor(data: Partial<OrderPaymentInfoModel>) {
        super(data);
        this.initialFmsPaymentType = this.fmsPaymentType === 'COMPTANT' ? PRICE_TYPES.Today : PRICE_TYPES.Reported;
    }
}

