export interface IGcpResponse {
    codePostal?: string;
    ville?: string;
    numero?: string;
    voie?: string;
    codeRetour?: {
        codeRetour?: string;
        msgRetour?: string;
    };
    complement?: string;
}

export const VALID_CODE_RETOUR_GCP = [
    '0',
    '-1',
    '-10',
    '-200',
    '-201',
    '-202'
];
