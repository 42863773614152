import { FAI_ELIGIBILITY_STATUS, FAI_TECHNO, FAI_TECHNO_PON } from '../../constants/fai';
import { Type } from 'class-transformer';
import { FaiVerticalStructureModel } from '@models/fai/Fai-vertical-structure.model';

export class FaiTechnoModel {

    public type: FAI_TECHNO;
    public status: FAI_ELIGIBILITY_STATUS;
    public debitUpMax: number;
    public debitUpMin: number;
    public debitDownMax: number;
    public debitDownMin: number;
    public offres: string[] = [];
    public homeZone: string;
    public technologiesPon?: FAI_TECHNO_PON[];
    public collectionNetwork: string;
    public allowForecast: {date?: string; eligible: boolean};
    @Type(()=>FaiVerticalStructureModel)
    public structureVerticales: FaiVerticalStructureModel[];

    constructor(data: Partial<FaiTechnoModel>) {
        Object.assign(this,data);
    }


}
