import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import * as icons from '../../../assets/svg-ts-icons/my-icons';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[bytelSvg]'
})
export class SvgDirective implements OnInit {

    /**
     * [Input description]
     * @return {[type]} [description]
     */
    @Input() public class: string;
    /**
     * [Input description]
     * @return {[type]} [description]
     */
    @Input() public name: string;
    /**
     * [Input description]
     * @return {[type]} [description]
     */
    @Input() public role: string;
    /**
     * [Input description]
     * @return {[type]} [description]
     */
    @Input() public title: string;
    protected sufixUrl = '';

    constructor(protected el: ElementRef, @Inject(DOCUMENT) private document: any // Any since Document fails with ngc
    ) {}

    /**
     * [ngAfterViewInit description]
     *
     * @return [description]
     */
    public ngOnInit(): void {

        const iconContent: string = icons[`${this._transformName(this.name)}`].data;
        const htmlFragment = this.document.createDocumentFragment();
        const svgElement = this.document.createElement('div');
        svgElement.innerHTML = this._editAttributeUrl(iconContent);
        htmlFragment.appendChild(svgElement);
        const svgProperties: string = this._addSvgProperties(htmlFragment.querySelector('svg'));

        const nodeParent = this.el.nativeElement.parentNode;
        nodeParent.removeChild(this.el.nativeElement);

        this.class = this.class?.concat(' bytelSvg-icon');

        this.role = this.role || 'img';
        this.title = this.title || '';

        nodeParent.innerHTML = `<svg aria-hidden="false" focusable="false" ${svgProperties}
        role="${this.role}" title="${this.title}" class="${this.class}">
        ${htmlFragment.querySelector('svg').innerHTML}</svg>`;
    }

    /**
     * @private
     * @memberof SvgDirective
     */
    private _transformName(name: string): string {

        let transformedName = '';

        for (let i = 0; i < name.length; i++) {

            const currentLetter: string = name[i];
            if (currentLetter !== '-') {

                if (name[i - 1] === '-') {
                    transformedName = transformedName.concat(name.split('')[i].toUpperCase());
                } else {
                    transformedName = transformedName.concat(currentLetter);
                }
            }
        }
        return transformedName;
    }

    /**
     * Retrieves properties from element (SVG) and add basic svg properties on the element
     *
     * @protected
     * @memberof SvgDrawDirective
     */
    private _addSvgProperties(element: Element): string {
        // Force default params to avoid empty viewBox
        const viewBox: {[key: string]: string | number} = {
            width: (element as any).viewBox.baseVal.width,
            height: (element as any).viewBox.baseVal.height,
            y: (element as any).viewBox.baseVal.y ||  0,
            x: (element as any).viewBox.baseVal.x || 0
        };

        const bgEnable = `enable-background="new ${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}"`;
        const xmlns = 'xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"';

        return `version="1.1" ${xmlns} width="100%" height="100%" ${bgEnable} viewBox="${viewBox.x} ${viewBox.y}
    ${parseFloat(viewBox.width.toString()).toFixed(2)} ${parseFloat(viewBox.height.toString()).toFixed(2)}"`;
    }

    /**
     * Change the url of all attribute to polyfill unsupported browser i.e SAFARI
     *
     * @private
     * @memberof SvgDirectiveBase
     */
    private _editAttributeUrl(svgData: string): string {

        if (!svgData) {
            return '';
        }
        const absUrl = window.location.href;
        return svgData.replace(/url\(#/gi, `url(${absUrl}#`);
    }

}
