export interface IAbandonedCart {
    typeParcours: string;
    offresAchetees: OffresAchetee[];
    controles: Controle[];
    acheteur: Acheteur;
    acteurCreateur: ActeurCreateur;
    montantTotalTTC: number;
    adresseIp: string;
    uuid: string;
    fingerprint: string;
    idPanier: string;
    email: string;
    noTelephone: string;
    adresseFacturation: AdresseFacturation;
    modeLivraison: ModeLivraison;
    comptesPayeursCrees: ComptesPayeursCree[];
    paiementInitial: PaiementInitial;
    dateCreation: string;
    canal: string;
}

export interface ICmdsCommercials {
    _links: any;
    items: ICmdCommercial[];
}

export interface ICmdCommercial {
    _links: any;
    id: string;
    idPanier: string;
    idPanierRetours: string;
    adresseIP: string;
    statut: string;
    dateEnregistrement: string;
    dateChangementStatut: string;
    causeChangementStatut: string;
    codeCauseChangementStatut: string;
    dateChangementCauseChangementStatut: string;
    montantTotalTTC: number;
    email: string;
    noTelephone: string;
    acteurCreateur: ActeurCreateur;
    paiementInitial: PaiementInitial;
    paiements: any[];
    modeLivraison: ModeLivraison;
    commandesExpedition: any[];
    adresseFacturation: AdresseLivraison;
    offresAchetees: OffresAchetee[];
    fichesRetour: FichesRetour[];
    actesGestion: any[];
    controles: Controle[];
    historiqueStatuts: HistoriqueStatut[];
    fingerprint: string;
    comptesPayeursCrees: ComptesPayeursCree[];
    UUID: string;
    acheteur?: Acheteur;
    version?: string;
}

export interface AdresseLivraison {
    ville: string;
    numero: string;
    codePostal: string;
    rue: string;
    pays: string;
    nom: string;
    prenom: string;
    civilite: string;
}

export interface FichesRetour {
    id: string;
    type: string;
    dateCreation: string;
    statut: string;
    elementsCommandes: any[];
    remplacementParcours: RemplacementParcours;
}

export interface HistoriqueStatut {
    statut: string;
    dateChangementStatut: string;
}

export interface RemplacementParcours {
    contexteRemplacementParcours: string;
    idParcoursVenteInitiale: string;
}

export interface PaiementInitial {
    montant: number;
    modePaiementSelectionne: string;
    modesPaiementInterdits: string[];
    edps?: any;
    dateLimitePaiement?: string;
    _actions?: {
        payerCommande?: PaiementInitialAction;
        notifierPaiementHybride?: PaiementInitialAction;
    };
}

interface PaiementInitialAction {
    action: string;
    type: string;
    method: string;
    input: {
        idCommande: string;
    };
}

export interface ComptesPayeursCree {
    idParcoursVente: string;
    compteBancaire: CompteBancaire;
}

export interface ModeLivraison {
    type: string;
    reseauLivraison: ReseauLivraison;
    adresseLivraison: AdresseFacturation;
    dateLivraisonEstimee: string;
    dateLivraisonEstimeeAuPlusTard: string;
    pointRelais?: PointRelais;
}

export interface ReseauLivraison {
    nomCommercial: string;
}

export interface AdresseFacturation {
    ville: string;
    numero: string;
    codePostal: string;
    rue: string;
    nom: string;
    prenom: string;
    civilite: string;
    pays: string;
}

export enum ACTEUR_CREATEUR_TYPES {
    CDC = 'CDC',
    CDV = 'CDV',
    PERSONNE = 'PERSONNE',
    TLV = 'TLV'
}

export interface ActeurCreateur {
    type: ACTEUR_CREATEUR_TYPES;
    noPersonne: string;
}

export interface Acheteur {
    noPersonne: string;
    estProspect: boolean;
    idIdentite: string;
    dateDeNaissance: string;
    departementNaissance: string;
}

export interface Controle {
    perimetre: string;
    retour: string;
}

export interface OffresAchetee {
    type: string;
    paiementComptantTTC?: number;
    elementsCommandes: ElementsCommande[];
    idParcoursVente: string;
    idOpportunite: string;
    libelle: string;
    dureeEngagement?: number;
    contexteContractuel?: ContexteContractuel;
    parcoursCreationCP?: ParcoursCreationCP;
    id?: string;
    statut?: string;
    documentsCommande?: string[];
    dateChangementActivite?: string;
    causeChangementActivite?: string;
    codeCauseChangementActivite?: string;
}

export interface ParcoursCreationCP {
    idParcoursVente: string;
}

export interface ContexteContractuel {
    type: string;
    compteBancaire: CompteBancaire;
    ligneMarche?: string;
    comptesPayeur?: string[];
    demandePortabilite?: {
        msisdnPorte: string;
        codeRio: string;
        typePortabilite: string;
    };
}

export interface CompteBancaire {
    iban?: string;
    bic?: string;
    modePaiement?: string;
    titulaire?: string;
}

export interface ElementsCommande {
    type: string;
    _type?: string;
    id?: string;
    libelleSI?: string;
    tarifsCommerciaux?: TarifsCommerciaux[];
    tarifCommercialAchat?: TarifCommercialAchat;
    libelleCommercial?: string;
    typeProduit?: string;
    gencode?: string;
    ecotaxe?: number | string;
    identifiantLogistique?: string;
    idElementCommandePanier?: string;
    categorieApresVente?: string;
    nature?: string;
    typePrime?: string;
    modaliteDemande?: string;
    url?: string;
    concerne?: Concerne;
    typeReglement?: string;
    typeLivraison?: string;
    reseauLivraison?: string;
    montantTotalEDP?: number;
    montantPrelevement?: number;
    nombrePrelevements?: number;
    noOffre?: string;
    statut?: string;
    activationAutomatique?: boolean;
    delaiActivation?: number;
    typeOffreOption?: string;
    typeOptionService?: string;
    caracteristiquesLigne?: CaracteristiquesLigne;
    adresseInstallation?: AdresseInstallation;
    tarifAchatSouscrit?: TarifSouscrit;
    emplacement?: string[];
    elementsDefauts?: string[];
    rendezVousRequis?: boolean;
    modeLivraison?: ModeLivraison;
    typeCodePromo?: string;
    identifiantAcces?: {
        login: string;
    };
    noVOIP?: {
        numero: string;
    };
    creneauRdvDemande?: {
        debutCreneau: string;
        finCreneau: string;
        idCreneau: string;
        idPrestataire: string;
        numeroContact: string;
        idExterne: string;
        idRDV: string;
    };
    demandePortabiliteFixe?: {
        rio: string;
        operateurDonneur: string;
        numeroAPorter: string;
    };
    logement?: {
        idPrise: string;
        listePto: string[];
        ndStatut: string;
        listeRouge: boolean;
    };
    produitsALivrer?: ProduitsALivrer[];
}

export interface Concerne {
    _type?: string;
    gencode?: string;
    idElementCommandePanier?: string;
    idElementCommande?: string;
    noOffre?: string;
}

export interface TarifCommercialAchat {
    typeTarif: string;
    nature: string;
    montantTTC: number;
    montantHT: number;
    tauxTVA: number;
    tarifSouscrit: TarifSouscrit;
    libelleCommercial?: string;
}

export interface TarifSouscrit {
    montantTTC: number;
    montantHT: number;
    tauxTVA: number;
}

export interface TarifsCommerciaux {
    typeTarif: string;
    nature?: string;
    montantTTC: number;
    montantHT?: number;
    tauxTVA?: number;
    libelleCommercial?: string;
}

export interface PointRelais {
    codeIdentification: string;
    codeSecteurLivraison: string;
    libelle: string;
}

export interface CaracteristiquesLigne {
    techno: string;
    reseau?: string;
    typeEligibilite?: string;
    statutNDI: string;
    ndiVoisin?: string;
    ndi?: string;
    typeDegroupage: string;
    constructionDeLigne: boolean;
    priseFtthExistant?: boolean;
    idPanierWebshop: string;
    idPriseFFTH?: string;
    numeroVoip?: string;
}

export interface AdresseInstallation {
    rue?: string;
    numero?: string;
    codePostal?: string;
    ville?: string;
    residence?: string;
    etage?: string;
    escalier?: string;
    batiment?: string;
    codeInsee?: string;
    codeRivoli?: string;
    porte?: string;
    logoFT?: string;
}
export interface ProduitsALivrer {
    idElementCommande:       string;
    idElementCommandePanier: string;
}
