export class FaiVerticalStructureModel{
    batiment: string;
    escalier: string;
    etage: string;
    presencePto?: boolean;
    ptos?: string[];
    ptoSelected: string;
    dateOuvertureCommerciale?: string;
    idPtoObligatoire?: boolean;
    constructor(data: Partial<FaiVerticalStructureModel>) {
        Object.assign(this,data);
    }
}
