import { Model } from '@models/model';
import { PortabilityPricingSim, PRICE_TYPES } from '@bytel/bytel-sales';
export class PortabilityPriceModel extends Model{

    public gencode: string;

    public withPorta: {
        price: number;
        type?: PRICE_TYPES;
    };

    public withoutPorta: {
        price: number;
        type?: PRICE_TYPES;
    };

    constructor(data: Partial<PortabilityPriceModel>) {
        super(data);
    }

    public getOldFormat(): PortabilityPricingSim{
        return {
            aUnePortabilite: {
                price: this.withPorta.price,
                type: this.withPorta.type
            },
            nAPasDePortabilite: {
                price: this.withoutPorta.price,
                type: this.withoutPorta.type
            }
        };
    }

}
