import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CartService as SalesCartService,
    Delivery,
    Edp,
    Equipment,
    IEdp,
    Phone, Plan,
    Product,
    ProductFactory,
    PromotionModel,
    Subscription,
    Fai,
    FaimUnlimited,
    Sim,
    Option,
    QuoteContextAcquisitionModel
} from '@bytel/bytel-sales';
import { MainCartModel } from '@models/cart/main-cart.model';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { FundingMethod } from '@models/payment-method';
import { HotToastService } from '@ngneat/hot-toast';
import { ProductRepository } from '@repositories/product.repository';
import { PromotionRepository } from '@repositories/promotion.repository';
import { SalesRepository } from '@repositories/sales.repository';
import { DeliveryService } from '@services/checkout/delivery.service';
import { PaymentMethodsService } from '@services/checkout/payment-methods.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { PromotionsService } from '@services/promotions.service';
import { SalesUserService } from '@services/sales-user.service';
import { SharedService } from '@services/shared.service';
import { concat, from, lastValueFrom, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, delayWhen, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { OptionCategory, OptionGencode } from 'src/app/constants/options';
@Injectable({
    providedIn: 'root'
})
export class CartActiveService extends SalesCartService {

    public refreshObs: Observable<MainCartModel>;
    public fundingMethod: FundingMethod;
    private _refreshSubject: ReplaySubject<MainCartModel> = new ReplaySubject(1);

    constructor(private promotionsService: PromotionsService,
                private portabilityService: PortabilityService,
                private salesRepository: SalesRepository,
                private faiEligibilityService: FaiEligibilityService,
                private paymentMethodsService: PaymentMethodsService,
                private promotionRepo: PromotionRepository,
                private deliveryService: DeliveryService,
                private toastService: HotToastService,
                private productRepo: ProductRepository,
                public salesUserService: SalesUserService) {
        super(promotionRepo, productRepo);
        this.refreshObs = this._refreshSubject.asObservable();
    }

    public addProduct(
        cartModel: MainCartModel,
        product: Product,
        quoteIndex: number = cartModel.currentQuoteIndex,
        autoRefresh: boolean = true
    ): Observable<Product> {
        return of(null)
            .pipe(
                mergeMap(() => super.addProduct(cartModel, product, quoteIndex,false)),
                delayWhen(() => { // Delivery
                    if (product instanceof Edp) {
                        return of(null);
                    }
                    const removeProduct = this.deliveryService.removeDelivery(cartModel, product, quoteIndex);
                    if (removeProduct){
                        return this.removeProduct(cartModel, removeProduct, quoteIndex, false);
                    }
                    return of(null);
                }),
                delayWhen(()=> product instanceof Delivery && cartModel.cartId && cartModel.getQuote(quoteIndex).id ?
                    this.deliveryService.pushDelivery(cartModel,cartModel.getQuote(quoteIndex),product) :
                    of(null)),
                // Remove "frais de coninuité de service" on add internet grantie
                delayWhen(()=> {
                    if ((product as Option)?.category === OptionCategory.internetGarantie) {
                        const edpProduct = cartModel.quotes[quoteIndex].getProductByGencode(OptionGencode.edpFms);
                        if (edpProduct) {
                            return this.removeProduct(cartModel, edpProduct, quoteIndex);
                        }
                    }
                    return of(null);
                }),
                // VOIR AVEC PIERRE VENUAT POURQUOI ON EST OBLIGER DE FAIRE CETTE CHOSE....
                delayWhen(()=> this._checksBeforeProductsListUpdate(product, cartModel, quoteIndex)),
                catchError((err: HttpErrorResponse) => {
                    this.toastService.error('Erreur: ' + (err?.error?.error_description || SharedService.DEFAULT_API_ERROR_MESSAGE));
                    return throwError(()=>err);
                }),
                delayWhen(()=> autoRefresh ? from(this.refresh(cartModel)) : of(null)),
                map(() => product)
            );
    }

    public setCouponPromotion(cart: MainCartModel, promotion: PromotionModel,withOutPush: boolean = false): Observable<void> {
        return (withOutPush ? of(null) : this.salesRepository.pushCoupon(cart,promotion))
            .pipe(mergeMap(()=>super.setCouponPromotion(cart, promotion)));
    }

    public removeCouponPromotion(cartModel: MainCartModel,refresh: boolean = true): Observable<void> {
        return this.salesRepository.removeCoupon(cartModel)
            .pipe(mergeMap(()=>super.removeCouponPromotion(cartModel,refresh)));
    }

    public async refresh(cartModel: MainCartModel): Promise<void> {
        cartModel.isPro = cartModel.hasProProduct();
        return super.refresh(cartModel)
            .then(async ()=>{
                // Check coupon allready aviable
                if (cartModel.promotions.coupon?.code) {
                    const cartPromotions = this.promotionsService.getCouponPromotionForCart(cartModel, cartModel.promotions.coupon.code)
                        .pipe(mergeMap((coupon)=>{
                            if (coupon && !coupon.equal(cartModel.promotions.coupon)){
                                return this.setCouponPromotion(cartModel, coupon,true);
                            } else if (!coupon){
                                return this.removeCouponPromotion(cartModel,false);
                            }
                            return of(null);
                        }));
                    return lastValueFrom(cartPromotions);
                }
            })
            .then(async ()=> {
                const availableFundings = this.paymentMethodsService.updateFundingMethodsFromMOCK(cartModel).pipe(
                    delayWhen((fundingMethod) =>
                        fundingMethod && fundingMethod?.type !== cartModel.fundingMethod?.type ?
                            this.setFundingMethod(cartModel, fundingMethod, false) : of(null)
                    ),
                    map(()=>null)
                );
                return lastValueFrom(availableFundings);
            })
            .then(()=>{this.portabilityService.currentCartModel = cartModel;})
            .finally(()=>this._refreshSubject.next(cartModel));
    }

    public removeProduct(cartModel: MainCartModel,
                         product: Product,
                         quoteIndex?: number,
                         autoRefresh: boolean = true): Observable<Product> {
        return product ? super.removeProduct(cartModel, product, quoteIndex, false).pipe(
            mergeMap(() => { // Delivery
                if (product instanceof Edp) {
                    return of(product);
                }
                const removeProduct = this.deliveryService.removeDelivery(cartModel, product, quoteIndex);
                if (removeProduct) {
                    return this.removeProduct(cartModel,removeProduct,quoteIndex,false);
                }
                return of(product);
            }),
            tap(()=>{
                if (ProductFactory.Is(product,Plan)){
                    this.portabilityService.clear();
                }
            }),
            mergeMap(() => { // MobileRecovery if remove Phone
                const removeProduct: MobileRecoveryModel = cartModel.getQuote().getProductByType(MobileRecoveryModel);
                if (removeProduct && ProductFactory.Is(product, Phone, true)) {
                    return this.removeProduct(cartModel,removeProduct,quoteIndex,false);
                }
                return of(product);
            }),
            delayWhen(()=> this._checksBeforeProductsListUpdate(product, cartModel, quoteIndex)),
            catchError((err: HttpErrorResponse) => {
                this.toastService.error('Erreur: ' + (err?.error?.error_description || SharedService.DEFAULT_API_ERROR_MESSAGE));
                return throwError(()=>err);
            }),
            mergeMap(()=> autoRefresh ? from(this.refresh(cartModel)) : of(null)),
            map(()=>product)
        ) : of(null);
    }

    public removeEdpFromScoring(
        cartModel: MainCartModel,
        product: Product,
        quoteIndex?: number,
        autoRefresh?: boolean
    ): Observable<Product> {
        return super.removeProduct(cartModel, product, quoteIndex).pipe(
            mergeMap(()=> autoRefresh ? from(this.refresh(cartModel)) : of(null)),
            map(()=>product)
        );
    }

    public setFundingMethod(cartModel: MainCartModel, fundingMethod: FundingMethod, refresh: boolean = true): Observable<boolean> {
        if (!cartModel.availableFundingMethods.some((funding) => funding.type === fundingMethod.type)) {
            return of(false);
        }
        let obs$: Observable<boolean>;
        const indexAcquisition = cartModel.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        if (fundingMethod.type === 'EDP') {
            obs$ = this.addProduct(cartModel, new Edp({} as IEdp), indexAcquisition, false).pipe(map(() => true));
        } else {
            obs$ = this.removeProduct(cartModel, cartModel.getQuote().getProductByType(Edp), indexAcquisition, false)
                .pipe(map(() => true));
        }
        return obs$.pipe(
            tap(() => cartModel.fundingMethod = fundingMethod),
            delayWhen(() => refresh ? from(this.refresh(cartModel)) : of(null))
        );
    }

    public removeManualPromotion(cartModel: MainCartModel, promotion: PromotionModel): Observable<void> {
        return super.removeManualPromotion(cartModel, promotion)
            .pipe(
                map(()=>null)
            );
    }

    public addManualPromotion(cartModel: MainCartModel, promotion: PromotionModel): Observable<void> {
        return super.addManualPromotion(cartModel, promotion)
            .pipe(
                map(()=>null)
            );
    }

    public addProducts(cartModel: MainCartModel, productsList: (Product | string)[], quoteIndex: number = 0): Observable<Product[]> {
        return of(productsList.filter(p=>!!p)).pipe(
            mergeMap((products)=>{
                if (products[0] instanceof Product){ return of(products); }
                return this.productRepo.getProductsByAttribute(products as string[],'gencode');
            }),
            map(this._sortProductForAdd.bind(this)),
            mergeMap((products: Product[]) => concat(
                ...products.map((product: Product) => this.addProduct(cartModel, product, quoteIndex, false))
            )),
            toArray(),
            mergeMap((products) => from(this.refresh(cartModel)).pipe(map(()=>products)))
        );
    }

    public deleteParcours(cartId: number, quoteId: string): void {
        this.salesRepository.deleteParcours(cartId, quoteId).subscribe();
    }

    public deleteManualPromotion(cartModel: MainCartModel): Observable<void> {
        return this.salesRepository.deleteManualPromotion(cartModel).pipe(
            mergeMap(()=>super.removeCouponPromotion(cartModel))
        );
    }

    public getFaiService(): FaiEligibilityService {
        return this.faiEligibilityService;
    }

    private _sortProductForAdd(products: Product[]): Product[] {
        const orderToAdd: (new (...args: any[]) => Product)[] = [
            Subscription,
            Equipment,
            Edp,
            Delivery
        ];
        products.sort((productA,productB) => {
            const indexOrderA = orderToAdd.findIndex((orderClass)=> ProductFactory.Is(productA,orderClass,true));
            const indexOrderB = orderToAdd.findIndex((orderClass)=> ProductFactory.Is(productB,orderClass,true));
            return (indexOrderA === -1 ? -Infinity : indexOrderA) - (indexOrderB === -1 ? -Infinity : indexOrderB);
        });
        return products;
    }

    private _checksBeforeProductsListUpdate(product: Product, cartModel: MainCartModel, quoteIndex: number): Observable<boolean> {
        const plan: Plan = cartModel.getQuote(quoteIndex,true).getProductByType(Plan);
        const sim: Sim = cartModel.getQuote(quoteIndex,true).getProductByType(Sim);
        if ((product instanceof Delivery) || (product instanceof Sim) || /* ((sim?.data as any)?.isEsim) || */
            (plan && !sim && (!ProductFactory.Is(plan, Fai) || ProductFactory.Is(plan, FaimUnlimited)) || (product instanceof Edp))
        ) {
            return of(null);
        }
        return this.salesRepository.pushProducts(
            cartModel.cartId,
            cartModel.getQuote(quoteIndex, true),
            this.portabilityService.getPortabilityFromCartModel(quoteIndex),
            this.faiEligibilityService.currentCart
        );
    }
}
