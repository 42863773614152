import { Model } from '@models/model';

export class FaiOfferModel extends Model {

    public name: string;
    public price: number;
    public equipmentName: string;
    public fmsPrice: number;
    public obligation: number;
    public discount: number;
    public priceWithDiscount: number;
    public id: string;
    public manualPromotionId?: string;

    public constructor(data: Partial<FaiOfferModel>) {
        super(data);
    }
}
