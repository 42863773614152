export class Oauth2IframeClass {

    public static injectIframe(src: string): HTMLIFrameElement {

        const iframe: any = document.createElement('iframe');

        iframe.src = src;
        iframe.sandbox = 'allow-scripts allow-same-origin';
        iframe.scrolling = 'no';
        iframe.style.display = 'none';

        document.querySelector('body').appendChild(iframe);

        return iframe as HTMLIFrameElement;
    }

    public static removeEl(node: Node): void {
        document.querySelector('body').removeChild(node);
    }

}
