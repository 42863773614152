import { SimpleChanges, Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { ConfigurationService } from '@common-modules';

@Directive({
    selector: '[tlvImageLoading]'
})
export class UrlPictureDirective implements OnInit, OnChanges {

    @Input() btlSrc: string;
    @Input() btlSrcSet: string;
    @Input() btlSize: string;

    public defaultSize: string = '400w';

    constructor(private configuration: ConfigurationService, private el: ElementRef){
        const supports = 'loading' in HTMLImageElement.prototype;

        if (supports) {
            el.nativeElement.setAttribute('loading', 'lazy');
        }
    }

    public ngOnInit(): void{
        this._updateImageInfos();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.firstChange) {
            this._updateImageInfos();
        }
    }

    protected getImgUrl(url: string): string{
        if (!url) {return;}
        const domain: string = this.configuration.data_refconf.origin;
        let normalizeImageUrl: string = url?.replace(/(\/\/)/, '/');
        if (normalizeImageUrl && !normalizeImageUrl.startsWith('/')) {
            normalizeImageUrl = '/'.concat(normalizeImageUrl);
        }
        if (url.includes(domain)) {
            return url;
        }
        return url && `${domain}${normalizeImageUrl}`;
    }

    private _updateImageInfos(): void {
        const Imageurl: string = this.btlSrc;
        let imagesUrl: string[] = this.btlSrcSet?.split(',');
        const imagesSize: string[] = this.btlSize?.split(',');
        if (imagesUrl?.length && imagesSize?.length && !imagesUrl.includes('undefined')) {
            imagesUrl = imagesUrl.map((url, index) => this.getImgUrl(url) + ` ${imagesSize[index] || this.defaultSize}` );
            this.el.nativeElement.setAttribute('srcset', imagesUrl.join(', '));
        } else {
            this.el.nativeElement.setAttribute('src', this.getImgUrl(Imageurl));
        }
    }

}
