import { Injectable } from '@angular/core';
import { CookieStorageService } from '../../services/cookie-storage.service';
import { ObjectOfString } from '../../interfaces/services.interface';

@Injectable({
    providedIn: 'root'
})
export class Oauth2StorageService {
    public KEY: ObjectOfString = {
        STATE: 'state',
        NONCE: 'nonce',
        ACCESS_TOKEN: 'access_token',
        ID_TOKEN: 'id_token',
        CONTRACT_ID: 'contract_id',
        CUSTOMER: 'customer',
        CONTRACTS_INFOS: 'contracts_infos',
        PARAMS: 'params'
    };

    protected prefix: string = 'oauth2_';

    public constructor(private cookie: CookieStorageService) { }

    public clear(storageInstance?: Storage): void {
        if (storageInstance instanceof Storage) {
            this.clearAllPrefix(storageInstance);
        } else {
            this.clearAllPrefix(sessionStorage);
            this.clearAllPrefix(localStorage);
        }
    }

    /**
     * Return item from storage
     * If expiresTimestamp is not null and lower then Now remove item and returns null
     */
    public getItem<T = any>(key: string): T {
        let item: T;
        const data: string = this._getStorageInstance().getItem(this.prefix + key);

        if (data !== null) {
            const storageObject: any = JSON.parse(data);
            if (storageObject.expiresTimestamp !== null) {
                if (storageObject.expiresTimestamp < new Date().getTime()) {
                    this.removeItem(key);
                } else {
                    item = storageObject.data;
                }
            } else {
                item = storageObject.data;
            }
        }
        return item;
    }

    public removeItem(key: string): void {
        this._getStorageInstance().removeItem(this.prefix + key);
    }

    /**
     * Use item of StorageInterface instead of simple key value to make storage expires
     */
    public setItem(key: string, value: unknown, expires: number = null): void {
        const storageObject: any = {
            data: value,
            expiresTimestamp: expires
        };
        this.removeItem(key);
        this._getStorageInstance().setItem(this.prefix + key, JSON.stringify(storageObject));
    }

    /**
     * Use item of StorageInterface instead of simple key value to make storage expires
     */
    public setUniversalItem(key: string,
                            value: any,
                            expires: number                = null,
                            preserveCompatibility: boolean = true): void {
        const storageObject: any = {
            data: value,
            expiresTimestamp: expires
        };
        this.removeItem(key);
        this._getStorageInstance().setItem(this.prefix + key, JSON.stringify(preserveCompatibility ? value : storageObject));
    }

    protected _getStorageInstance(): Storage {
        return this.cookie;
    }

    protected clearAllPrefix(storageInstance: Storage): void {
        const keys: string[] = Object.keys(storageInstance);
        keys.forEach(key => {
            if (key.startsWith(this.prefix)) {
                storageInstance.removeItem(key);
            }
        });
    }
}
