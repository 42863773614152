import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[InputOnlyNumber]'
})
export class InputOnlyNumberDirective {

    constructor(
        private el: ElementRef
    ) {}

    @HostListener('input', ['$event'])
    onEvent(): void {
        this.el.nativeElement.value = this.el.nativeElement.value
            .replace(new RegExp(/\D/, 'gi'), '')
            .replace(/\s/g, '');
    }

}
