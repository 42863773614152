<div class="tabs is-centered is-fullwidth is-marginless" *ngIf="isMobileMQ" role="tablist">
    <ul>
      <li role="presentation" (click)="changeTab(tabChoices.STORES)">
        <a role="tab" [ngClass]="{'is-active': currentTab === tabChoices.STORES}" aria-selected="true">Liste</a>
      </li>
      <li role="presentation" (click)="changeTab(tabChoices.MAP)" *ngIf="stores?.length">
        <a role="tab" [ngClass]="{'is-active': currentTab === tabChoices.MAP}">Carte</a>
      </li>
    </ul>
</div>
<div class="box is-paddingless">
    <div class="columns is-marginless is-mobile">
        <div [ngClass]="{'is-hidden': isMobileMQ && currentTab !== tabChoices.STORES}" class="column is-6-tablet is-paddingless is-narrow-tablet">
            <div class="columns is-marginless is-multiline">
                <div class="column">
                    <div class="is-marginless" [ngPlural]="stores?.length">
                        <ng-template ngPluralCase="=0">
                            <p>Aucun magasin n’est disponible à l’adresse indiquée.</p>
                            <p>Vous pouvez modifier l’adresse ou sélectionner un autre mode de livraison.</p>
                        </ng-template>
                        <ng-template ngPluralCase="=1">Le magasin le plus proches de&nbsp;:</ng-template>
                        <ng-template ngPluralCase="other">Les <span class="has-text-weight-semibold">{{stores?.length}}</span> magasins les plus proches de&nbsp;:</ng-template>
                    </div>
                    <div class="is-flex is-vcentered">
                        <span class="icon is-small is-marginless" *ngIf="showDeliveryMapIcon">
                            <span bytelSvg name="shape-icon-home-location" title></span>&nbsp;
                        </span>
                        <p bytelCsMask class="has-text-info">{{address}}</p>
                    </div>
                </div>
                <div class="column is-narrow">
                    <a href="" (click)="changeAddressEvent($event)" class="link has-text-info">Modifier l'adresse</a>
                </div>
                <div class="column is-12">
                    <div class="is-divider is-marginless"></div>
                </div>
            </div>
            <div class="stores-container">
                <div *ngFor="let store of stores; let last = last;">
                <bytel-store
                    *ngIf="!store.isDeliveryAddress"
                    (selectStoreEvent)="handleSelectedStore($event)"
                    [isLast]="last"
                    [store]="store"
                    [isSelected]="store && store.id === currentStore?.id"
                    [id]="'store-'+store.id"
                    [shouldAvoidScrollDelay]="shouldAvoidScrollDelay"
                ></bytel-store>
                </div>
            </div>
        </div>
        <div [ngClass]="{'is-hidden': isMobileMQ && currentTab !== tabChoices.MAP}" class="column is-paddingless map-tab">
            <bytel-gmaps *ngIf="stores.length"
                (markerSelectedEvent)="handleSelectedMarker($event)"
                [markers]="markers"
                [mapConfig]="mapConfig"
                [isLoading]="!stores.length"
                [updatePosition]="currentMarker"
            ></bytel-gmaps>
            <div class="box selected-store" *ngIf="isMobileMQ && currentStore && displayStoreModal && !currentStore.isDeliveryAddress">
                <p *ngIf="isMobileMQ">
                    <span (click)="hideModal()" class="close-selected-store icon is-small">
                        <i class="tri-times" aria-hidden="true"></i>
                    </span>
                </p>
                <bytel-store
                    [isStandAlone]="true"
                    [isLast]="true"
                    [store]="currentStore"
                    [isSelected]="false"
                    [shouldAvoidScrollDelay]="shouldAvoidScrollDelay"
                ></bytel-store>
            </div>
        </div>
    </div>
</div>
