import { Model } from '../model';
import { FaiAddressModel } from '@models/fai/fai-address';
import { FAI_CART_TYPE } from '../../constants/fai';
import { Type } from 'class-transformer';
import { FaiLogementModel } from '@models/fai/fai-logement.model';
import { FaiOfferModel } from '@models/fai/fai-offer.model';
import { FaiPtoModel } from '@models/fai/fai-pto.model';
export class OrderFaiInfoModel extends Model{

    @Type(()=>FaiAddressModel)
    public address?: FaiAddressModel;
    @Type(()=>FaiLogementModel)
    public logement: FaiLogementModel;
    @Type(()=>FaiOfferModel)
    public offer: FaiOfferModel;
    @Type(()=>FaiPtoModel)
    public pto: FaiPtoModel;
    public ndi?: string;
    public type: FAI_CART_TYPE;
    public isCDL: boolean;

}
