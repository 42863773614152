export interface IControlerSirenRepresentantLegal {
    civilite?: ControlerSirenRepresentantLegal.CiviliteEnum;
    dateNaissance?: string;
    nom: string;
    prenom: string;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ControlerSirenRepresentantLegal {
    export type CiviliteEnum = 'M.' | 'MLLE' | 'MME';
    export const CiviliteEnum = {
        M: 'M.' as CiviliteEnum,
        MLLE: 'MLLE' as CiviliteEnum,
        MME: 'MME' as CiviliteEnum
    };
}
