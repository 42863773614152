import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[bytelCy]'
})
export class DataCyDirective implements OnInit {
    @Input() bytelCy: string;

    constructor(private el: ElementRef) {}

    public ngOnInit(): void {
        this.el.nativeElement.setAttribute('data-cy', this.bytelCy);
    }
}
