export class FaiLogementModel{

    public codePostal: string;
    public codeInsee: string;
    public codeVoie: string;
    public codeNumeroVoie: string;
    public numeroVoie = '';
    public extension = '';
    public escalier: string;
    public etage: string;
    public porte: string;
    public logo: string;
    public residence: string;
    public batiment: string;
    public codeLogement: string;
    public voie: string;
    public voieTechnique: string;
    public commune: string;
    public typeVoie: string;
    public numeroDepartement: string;
    public ndStatut: string;
    public listeRouge: boolean;
    public nomTitulaire: string;
    public ndi: string;
    public logementNonTrouve: boolean;

    public constructor(data: Partial<FaiLogementModel>) {
        Object.assign(this,data);
    }
}
