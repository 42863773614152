export class FaiStepsModel {

    public steps: string[] = [];

    public setStep(step: string): void {
        this.steps.push(step?.toUpperCase());
    }

    public getPreviousStep(): string {
        return this.steps[this.steps.length - 1];
    }

}
