import { Model } from '@models/model';

export class EmailValidationModel extends Model{

    public userGeneric: boolean;
    public correctEmailSyntax: boolean;
    public validEmail: boolean;
    public temporaryEmail: boolean;
    public freeEmail: boolean;
    public emailContact: boolean;
    public domainBbox: boolean;
    public domainExist: boolean;

    constructor(data: Partial<EmailValidationModel>){
        super(data);
    }

    public isInvalid(): boolean{
        return this.domainExist === false ||
        this.temporaryEmail === true ||
        this.correctEmailSyntax === false ||
        this.userGeneric === true ||
        this.validEmail === false;
    }

}
