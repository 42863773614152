import { BasicObject } from '@bytel/bytel-sales';
import { Model } from './model';

export class FundingMethod extends Model{

    public coutTotalCredit: number;
    public dateFinancement: string;
    public datePremiereEcheance: string;
    public derniereMensualite: number;
    public idOption: number;
    public libelleOption: BasicObject;
    public mensualite: number;
    public montant: number;
    public montantTotalDu: number;
    public nbEcheance: number;
    public taeg: number;
    public type: string;
    public paymentMethods: string[];
    public label: string;

    constructor(data: Partial<FundingMethod>) {
        super(data);
    }
}
