import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { CustomFormControl } from '@helper/form-control/custom-form-control';
import { IOpportuniteOp } from '@interfaces/opportunity.interface';
import { DialogRef } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OpportunityService } from '@services/opportunity.service';

@Component({
    selector: 'tlv-form-dadcommission-modal',
    templateUrl: './form-dadcommission-modal.component.html',
    styleUrls: ['./form-dadcommission-modal.component.scss']
})
export class FormDadCommissionModalComponent implements OnInit {

    public isLoading: boolean = false;
    public listOp: IOpportuniteOp[] = [];
    public form: FormGroup = new FormGroup({
        idCase: new CustomFormControl('', [Validators.required]),
        opCase: new CustomFormControl()
    });

    constructor(
        public ref: DialogRef,
        private opportunityService: OpportunityService,
        private cartTeleSalesService: CartTeleSalesService
    ) { }

    public get idCase(): AbstractControl<string> { return this.form.get('idCase'); }
    public get opCase(): AbstractControl<string> { return this.form.get('opCase'); }
    public get idOpportunitiesUsed(): string[] { return this.cartTeleSalesService.getIdOpportunitiesUsed();}


    public ngOnInit(): void {
        this._initFormValues();
    }

    public submitForm(): void {
        if (!this.idCase.value) { return; }
        if (this.opCase.value) {
            this._saveOpCaseAndContinue();
        } else {
            this._getListOpByIdCase();
        }
    }

    private _initFormValues(): void {
        this.form.patchValue({ idCase: this.opportunityService.opportunity.opportunityDad.idCase });
        if (this.opportunityService.opportunity.opportunityDad.opportunitiesList.length) {
            this.form.controls.idCase.disable();
            this.listOp = this.opportunityService.opportunity.opportunityDad.opportunitiesList;
        }
    }

    private _saveOpCaseAndContinue(): void {
        this.isLoading = false;
        this.opportunityService.onChangeOpportunityDad(this.idCase.value, this.opCase.value);
        this.opportunityService.setOpportunitiesList(this.listOp);
        this.ref.close({ continue: true });
    }

    private _getListOpByIdCase(): void {

        if (this.form.controls.idCase.getError('noId')) {
            this._saveOpCaseAndContinue();
        }

        this.isLoading = true;
        this.listOp = [];

        this.opportunityService.getListOp(this.idCase.value).subscribe(
            {
                next: (data) => {
                    const listOpportuniteOp = data.filter((opportuniteOp) => opportuniteOp?.idOpportunite);
                    if (listOpportuniteOp.length) {
                        this.form.controls.idCase.disable();
                        this.listOp = listOpportuniteOp;
                        this._preSelectOpportunityIfFound();
                    } else {
                        this.form.controls.idCase.setErrors({ noId: true });
                    }
                    this.isLoading = false;
                },
                error: () => {
                    this.form.controls.idCase.setErrors({ error: true });
                    this.isLoading = false;
                }
            });
    }

    private _preSelectOpportunityIfFound(): void {
        const idOpportunity = this.opportunityService.opportunity.opportunityDad.idOpportunity;
        if (this.listOp.some(opportunity => opportunity.idOpportunite === idOpportunity)) {
            this.form.controls.opCase.setValue(idOpportunity);
        }
    }

}

