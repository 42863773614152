import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { catchError, mergeMap, retry, share, tap } from 'rxjs/operators';
import { BasicObject, ObjectOfString } from '../../interfaces/services.interface';
import { Oauth2Service } from './oauth2.service';
import { BtlPrismeService } from '../btl-prisme.service';
import { ConfigurationService } from '../configuration.service';
import { CustomHttpParamEncoder } from './custom-http-param-encoder.class';
import {v4 as uuidv4} from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class Oauth2ResourcesService {
    public static HTTP_BAD_REQUEST: number = 400;
    public static HTTP_FORBIDDEN: number = 403;
    public static HTTP_INTERNAL_ERROR: number = 500;
    public static HTTP_NOT_FOUND: number = 404;
    public static HTTP_OK: number = 200;
    public static HTTP_UNAUTHORIZED: number = 401;

    public inService: boolean = false;
    public store;

    protected forceIE11: boolean = false;
    protected headers: BasicObject = {};
    protected localService: boolean = false;
    protected params: HttpParams;
    protected resourceUrl: string = '';
    protected retryOption: Parameters<typeof retry>[0];

    constructor(
        protected oauth2: Oauth2Service,
        protected http: HttpClient,
        protected configService: ConfigurationService,
        protected prismeService: BtlPrismeService) {
        this.params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    }

    public abonnement(): this {
        return this.getResource('/abonnement');
    }

    public accessOnline(): this {
        return this.getResource('/accessOnline');
    }

    public action(): this {
        return this.getResource('/action');
    }

    public addCache(): this {
        return this.addHeaders({ 'Cache-Control': 'cache-js' });
    }

    public autorisationVente(): this {
        return this.getResource('/autorisation-vente');
    }
    public retry(options: Parameters<typeof retry>[0]): this {
        this.retryOption = options;
        return this;
    }

    public addHeaders(headers: BasicObject): this {
        const resource: this = this.getInstance();
        resource.headers = Object.assign(resource.headers, headers);
        return resource;
    }

    public adresseLivraison(): this {
        return this.getResource('/adresses-livraison');
    }

    public clickAndCollect(): this {
        return this.getResource('/click-and-collect');
    }

    public pointRelais(): this {
        return this.getResource('/points-relais');
    }

    public adresseFacturation(): this {
        return this.getResource('/adresse-facturation');
    }

    public assuranceSeuleContrat(): this {
        return this.getResource('/assurance-seule-contrat');
    }

    public atosContract(): this {
        return this.getResource('/contractualisation');
    }

    public availableOptions(plan: string): this {
        const resourceUrl = `/options-disponibles/${plan}`;
        return this.getResource(resourceUrl);
    }

    public avantages(): this {
        return this.getResource('/avantages');
    }

    public banques(): this {
        return this.getResource('/referentiel-banques');
    }

    public calculImpact(): this {
        return this.getResource('/visionImpactMigration');
    }

    public recommendations(): this {
        return this.getResource('/recommandations');
    }
    public consulterOpportunite(idOpportuniteGP): this {
        return this.getResource('/opportunites-gp/' + idOpportuniteGP);
    }
    public rechercherOpportunite(idCase: string): this {
        return this.getResource('/opportunites-gp?numeroCase=' + idCase);
    }

    public feedbackInteraction(): this {
        return this.getResource('/retour-interaction');
    }

    public categories(id?: string): this {
        return this.getResource(`/categories${id ? '/' + id : ''}`);
    }

    public codeInsee(codeInsee: string): this {
        return this.getResource('/' + codeInsee);
    }

    public codeNumeroVoie(codeNumeroVoie: string): this {
        return this.getResource('/' + codeNumeroVoie);
    }

    public codeVoie(codeVoie: string): this {
        return this.getResource('/' + codeVoie);
    }

    public commande(id?: number | string): this {
        let resourceUrl = '/commande';
        if (id) {
            resourceUrl += '/' + id.toString();
        }
        return this.getResource(resourceUrl);
    }

    public compatibiliteSim(): this {
        return this.getResource('/compatibilite-sim');
    }

    public comptesFacturation(id?: string): this {
        const resourceUrl = '/comptes-facturation' + (id ? '/' + id : '');
        return this.getResource(resourceUrl);
    }

    public adressesFacturation(): this {
        return this.getResource('/adresses-facturation');
    }

    public config(): this {
        return this.getResource('/config');
    }

    public configuration(): this {
        return this.getResource('/configuration');
    }

    public consulterDocuments(id: string): this {
        const resourceUrl = '/consulter-documents' + (id ? '/' + id : '');
        return this.getResource(resourceUrl, true);
    }

    public contracts(contractId: number | string): this {
        const resourceUrl = '/contrats/' + contractId;
        return this.getResource(resourceUrl);
    }

    public validationEmail(): this {
        return this.getResource('/adresses/validation-email');
    }

    public contratsSignes(contractId?: string): this {
        return this.getResource('/contrats-signes' + (contractId ? '/' + contractId : ''));
    }

    public contratsUtilises(): this {
        return this.getResource('/contrats-utilises');
    }

    public controleCarte(type: string, data: string): this {
        return this.getResource(`/controle-carte/type/${type}/donnees/${data}`);
    }

    public controleInsee(codeInsee?: string): this {
        const resourceUrl = `/controle-insee${codeInsee ? '/' + codeInsee : ''}`;
        return this.getResource(resourceUrl, true);
    }

    public controlePortabilite(): this {
        return this.getResource('/controle-portabilite');
    }

    public controleRisquesVente(): this {
        return this.getResource('/controles-risques-vente');
    }

    public verificationsBancaires(): this {
        return this.getResource('/verifications-bancaires');
    }

    public demande(): this {
        return this.getResource('/demande');
    }

    public status(idPerson: string): this {
        return this.getResource(`/${idPerson}/statut`);
    }

    public controleSiren(siren: string): this {
        const resourceUrl = '/controleSiren/' + siren;
        return this.getResource(resourceUrl);
    }

    public coordonnees(): this {
        return this.getResource('/coordonnees');
    }

    public coordonneesContact(): this {
        return this.getResource('/coordonnees-contact');
    }

    public creneauxDisponibles(): this {
        return this.getResource('/creneaux-disponibles', false);
    }

    public creneauxRappel(): this {
        return this.getResource('/creneaux-rappel');
    }

    public creneauxLivraison(): this {
        return this.getResource('/creneaux-livraison');
    }

    public recherche(): this {
        return this.getResource('/recherche');
    }

    public datePortageParDefaut(): this {
        return this.getResource('/datePortageParDefaut');
    }

    public delete<T = any>(): Observable<T> {
        return this.getRequest('DELETE');
    }

    public dereserveMsisdns(action: string, msisdn: string): this {
        const resourceUrl = '/msisdns/' + msisdn;
        const resource: this = this.getResource(resourceUrl, true);

        const params: BasicObject = {};
        if (action) {
            params.action = action;
        }

        resource.setParams(params);
        return resource;
    }

    public dereserverRendezVous(id?: string): this {
        const url = id ? '/' + id : '';

        return this._getRdv(url);
    }

    public details(): this {
        return this.getResource('/boutiques/details', true);
    }

    public documents(id?: string): this {
        const resourceUrl = '/documents' + (id ? '/' + id : '');
        return this.getResource(resourceUrl, true);
    }

    public dossier(callBackUrl?: string): this {
        const resource: this = this.getResource('/dossier', true);
        if (callBackUrl) {
            resource.setParams({ callbackUrl: callBackUrl });
        }
        return resource;
    }

    public eligibilite(idPanier?: string): this {
        const resourceUrl = `/eligibilite${idPanier ? '/' + idPanier : ''}`;
        return this.getResource(resourceUrl);
    }

    public eligibiliteFai(idPanier?: string): this {
        let resourceUrl = '/eligibilite';
        if (idPanier) {
            resourceUrl += '/' + idPanier;
        } else {
            resourceUrl += '/';
        }
        return this.getResource(resourceUrl);
    }

    public eligibilitePortabilite(): this {
        return this.getResource('/eligibilite-portabilite');
    }

    public eligibiliteSouscriptionAssurances(): this {
        return this.getResource('/eligibilite-souscription-assurances');
    }

    public eligibiliteTokyo(): this {
        return this.getResource('/RsGacEligibilite/eligibilite-tokyo');
    }

    public eligibilitesPortabilite(): this {
        return this.getResource('/eligibilites-portabilite');
    }

    public eligibilitesPrevisionnelles(codeInsee: string): this {
        const resource: this = this.getResource('/eligibilites-previsionnelles');
        resource.setParams({ codeInsee });
        return resource;
    }

    public eligibilitesRenouvellement(): this {
        return this.getResource('/eligibilites-renouvellement', true);
    }

    public eligibilityEdpTerminalNu(): this {
        return this.getResource('/etalement-de-paiement-terminal-nu', true);
    }

    public eligibilityRenew(): this {
        return this.getResource('/eligibilite-edp-renouvellement', true);
    }

    public enregistrement(): this {
        return this.getResource('/enregistrement');
    }

    public entonnoir(idPanier?: string): this {
        let resourceUrl = '/entonnoir';
        if (idPanier) {
            resourceUrl += '/' + idPanier;
        }
        return this.getResource(resourceUrl);
    }

    public equipementsMobiles(): this {
        return this.getResource('/equipements-mobiles');
    }

    public ericsson(param: string): this {
        const resourceUrl = `/ericsson/${param}`;
        return this.getResource(resourceUrl);
    }

    public etalementDePaiementRenouvellement(): this {
        return this.getResource('/etalement-de-paiement-renouvellement');
    }

    public facturation(): this {
        return this.getResource('/adresses-facturation');
    }

    public financementsAutorises(): this {
        return this.getResource('/financements-autorises');
    }

    public gcp(): this {
        return this.getResource('/gcp');
    }

    public get<T = any>(): Observable<T> {
        if (this.forceIE11) {
            const timestamp = +new Date();
            this.setParams({
                tsp: timestamp
            });
        }
        return this.getRequest('GET');
    }

    public getClient(): HttpClient {
        return this.http;
    }

    public getUrl(): string {
        return this._getRequestUrl() + this.resourceUrl;
    }

    public getUserInfo(): ObjectOfString {
        const resource: this = this.getInstance();
        return resource.oauth2.playLoad;
    }

    public grilleTarifaire(): this {
        return this.getResource('/grille-tarifaire');
    }

    public iban(): this {
        return this.getResource('/iban');
    }

    public lignes(): this {
        return this.getResource('/lignes');
    }

    public listeProduits(): this {
        return this.getResource('/liste-produits', true);
    }

    public listerEquipements(): this {
        return this.getResource('/ListEquipements', true);
    }


    public lock(): this {
        return this.getResource('/lock');
    }

    public loginReservation(): this {
        return this.getResource('/logins', false);
    }

    public mettreAjourParcours(): this {
        return this.getResource('/mettre-ajour-parcours');
    }

    public mobileUsage(): this {
        return this.getResource('/mobile-usage');
    }

    public modesLivraison(): this {
        return this.getResource('/modes-livraison');
    }

    public livraisonsDisponibles(): this {
        return this.getResource('/livraisons-disponibles');
    }

    public modesPaiement(): this {
        return this.getResource('/modes-paiement');
    }

    public modesFinancement(): this {
        const url = '?younited_credit=false';
        return this.getResource(`/modes-financement${url}`);
    }

    public courant(): this {
        return this.getResource('/courant');
    }

    public msisdns(qt: number = 1, type: number = 7, time: string = 'temporaire', searchCriteria: string = null, action?: string): this {
        const resource: this = this.getResource('/msisdns', true);
        let params: BasicObject = {};
        if (action) {
            params.action = action;
        } else {
            params = {
                instanceCliente: 'GP1',
                quantite: qt.toString(),
                typeMsisdn: type.toString(),
                dureeReservation: time
            };
            if (searchCriteria) {
                params.critereRecherche = searchCriteria;
            }
        }

        resource.setParams(params);
        return resource;
    }

    public murProduits(): this {
        return this.getResource('/mur-produits');
    }

    public nd(nd: string): this {
        return this.getResource('/' + nd);
    }

    public numeroTel(): this {
        return this.getResource('/numero-tel');
    }

    public odr(): this {
        return this.getResource('/consulter-odr', true);
    }

    public offres(id?: number | string): this {
        const resourceUrl = `/offres${id ? '/' + id.toString() : ''}`;
        return this.getResource(resourceUrl, true);
    }

    public offresAdditionnellesSouscriptibles(): this {
        return this.getResource('/offres-additionnelles-souscriptibles', true);
    }

    public offresAutorisees(): this {
        return this.getResource('/offres-autorisees');
    }

    public options(): this {
        return this.getResource('/options');
    }

    public optionsSouscriptibles(): this {
        return this.getResource('/options-souscriptibles', true);
    }

    public paiement(): this {
        return this.getResource('/paiement');
    }

    public panier(id?: number | string): this {
        const resourceUrl = `/paniers${id ? '/' + id.toString() : ''}`;
        return this.getResource(resourceUrl, true);
    }

    public consulterPolitiqueTarifairePaniersApresVente(id?: string): this {
        const resourceUrl = '/paniers-apres-vente/' + id.toString() + '/politique-tarifaire';
        return this.getResource(resourceUrl, true);
    }

    public parcours(id?: number | string): this {
        const resourceUrl = `/parcours${id ? '/' + id.toString() : ''}`;
        return this.getResource(resourceUrl);
    }

    public personnes(personnesId?: number | string): this {
        let resourceUrl = '/personnes';
        if (personnesId) {
            resourceUrl += '/' + personnesId;
        }
        return this.getResource(resourceUrl);
    }

    public portability(): this {
        return this.getResource('/portabilites', false);
    }

    public portabilityVoip(): this {
        return this.getResource('/voips', false);
    }

    public post(body: unknown): Observable<any> {
        return this.getRequest('POST', body);
    }

    public postalCode(postalCode: string): this {
        return this.getResource('/' + postalCode);
    }

    public prixSim(): this {
        return this.getResource('/prix-sims');
    }

    public tarificationSims(): this {
        return this.getResource('/tarification-sims');
    }

    public simsContextualises(): this {
        return this.getResource('/sims-contextualises');
    }

    public catalogue(): this {
        return this.getResource('/catalogue');
    }

    public produits(id?: number | string): this {
        const resourceUrl = `/produits${id ? '/' + id : ''}`;
        return this.getResource(resourceUrl);
    }

    public produitsContextualises(): this {
        return this.getResource('/produits-contextualises');
    }

    public promotions(id?: number): this {
        const resourceUrl = `/promotions${id ? '/' + id : ''}`;
        return this.getResource(resourceUrl);
    }

    public detailsPromotion(id: string): this {
        const resourceUrl = `/promotions/${id}`;
        return this.getResource(resourceUrl);
    }

    public codesPromotionnels(): this {
        return this.getResource('/codes-promotionnels');
    }

    public promotionsDisponibles(): this {
        return this.getResource('/promotions-disponibles');
    }

    public eligibilitePromotionnelle(): this {
        return this.getResource('/eligibilite-promotionnelle');
    }

    public prospect(): this {
        return this.getResource('/prospect');
    }

    public prospectWEB(): this {
        return this.getResource('/prospectWEB');
    }

    public ptos(): this {
        return this.getResource('/ptos');
    }

    public put<T = any>(body: { [index: string]: any }): Observable<T> {
        return this.getRequest('PUT', body);
    }

    public referentiel(): this {
        return this.getResource('/referentiel');
    }

    public rendezVous(id?: string): this {
        const url = id ? '/?idCommande=' + id : '';
        return this._getRdv(url);
    }

    public repriseMobile(): this {
        return this.getResource('/reprise-mobile', true);
    }

    public ressources(): this {
        return this.getResource('/ressources');
    }

    public scoring(): this {
        return this.getResource('/scoring', true);
    }

    public scoringCanal(): this {
        return this.getResource('/scoringCanal', true);
    }

    public scoringInformation(): this {
        return this.getResource('/scoring-information', true);
    }

    public demandesCommunication(): this {
        return this.getResource('/demandes-communication');
    }

    public notifications(): this {
        return this.getResource('/espace-vente/notifications');
    }

    public notificationsActive(): this {
        return this.getResource('/espace-vente/notifications/active');
    }

    public notificationsStatus(id: string): this {
        return this.getResource(`/espace-vente/notifications/${id}/status`);
    }

    public session(): this {
        return this.getResource('/session');
    }

    public setLocalService(flag: boolean = true): this {
        const resource: this = this.getInstance();
        resource.localService = flag;
        return resource;
    }

    public setParams(params: { [index: string]: string | number }): this {
        const resource: this = this.getInstance();
        Object.keys(params).forEach((key: string) => {
            resource.params = resource.params.set(key, '' + params[key]);
        });
        return resource;
    }

    public setUrl(resourceUrl: string): this {
        const resource: this = this.getInstance();
        resource.resourceUrl += resourceUrl;
        return resource;
    }

    public stockBoutique(materialGroups: string): this {
        const resourceUrl = `/stock-boutique/${materialGroups}`;
        return this.getResource(resourceUrl, true);
    }

    public stocks(key?: string): this {
        const resourceUrl = `/stocks${key ? '/' + key : ''}`;
        return this.getResource(resourceUrl, true);
    }

    public techno(techno: string): this {
        return this.getResource('/' + techno);
    }

    public token(): this {
        return this.getResource('/token', true);
    }

    public useSalesApi(): this {
        const resource: this = this.getInstance();
        resource.headers['x-version'] = this.configService.baseConfig.salesApiVersion || '4';
        return resource;
    }

    public ventes(): this {
        this.setLocalService();
        return this.getResource('/ventes');
    }

    public verifierDossier(): this {
        return this.getResource('/verifierDossier', true);
    }

    public verifierEquipement(id: string): this {
        const resourceUrl = '/verifierEquipement/' + id;
        return this.getResource(resourceUrl, true);
    }

    public verifierPanier(): this {
        return this.getResource('/verifierPanier', true);
    }

    public voips(qt: number = 1, type: string = 'VOIP'): this {
        const resource: this = this.getResource('/voips', true);

        resource.setParams({
            nbNumVoip: qt.toString(),
            type
        });

        return resource;
    }

    public campagnesTelevente(): this {
        return this.getResource('/campagnesTelevente');
    }

    public listerAvantages(): this {
        return this.getResource('/listerAvantages');
    }

    public consulterContexteOperateur(): this {
        return this.getResource('/contexte-operateur');
    }

    public propositionsCommerciales(): this {
        return this.getResource('/propositions-commerciales');
    }

    public cms(id: string): this {
        const resourceUrl = '/cms/' + id;
        return this.getResource(resourceUrl);
    }

    public orcom(): this {
        const resourceUrl = '/orcom';
        return this.getResource(resourceUrl);
    }

    public commandesCommerciales(orderId?: string): this {
        const resourceUrl = `/commandes-commerciales${orderId ? '/' + orderId : ''}`;
        return this.getResource(resourceUrl);
    }

    public panierRetoursRepriseParcours(): this {
        const resourceUrl = '/panier-retours-reprise-parcours';
        return this.getResource(resourceUrl);
    }

    public useXProcess(): this {
        const resource: this = this.getInstance();
        resource.headers['X-Process'] = 'RECAPITULATIF_CONTRACTUEL';
        return resource;
    }

    public notifierPaiementHybride(): this {
        const resourceUrl = '/notifierPaiementHybride';
        return this.getResource(resourceUrl);
    }

    public produitsPartenaires(idPhone?: string, withDevis: boolean = false): this {
        let resourceUrl = '/produits-partenaires';
        if (idPhone) {
            resourceUrl += '/' + idPhone + (withDevis ? '/devis' : '');
        }
        return this.getResource(resourceUrl);
    }

    public orderSummary(cardId: string, paymentType?: string, isPaymentFmsDiffered?: boolean): this {
        let resourceUrl = `/closing/${cardId}`;

        if (paymentType && isPaymentFmsDiffered) {
            resourceUrl += `?paymentType=${paymentType}&isPaymentFmsDiffered=${isPaymentFmsDiffered}`;
            return this.getResource(resourceUrl);
        }

        if (paymentType) {
            resourceUrl += `?paymentType=${paymentType}`;
        }

        if (isPaymentFmsDiffered) {
            resourceUrl += `?isPaymentFmsDiffered=${isPaymentFmsDiffered}`;
        }

        return this.getResource(resourceUrl);
    }

    public openBankingIban(reportId: string): this {
        const resourceUrl = `/open-banking/iban/${reportId}`;
        return this.getResource(resourceUrl);
    }

    protected _getRequestOptions(): Record<string, unknown> {
        this.setTrackerId();

        if (this.configService.baseConfig.environment !== 'PROD' && this.configService.baseConfig.environment !== 'LOCAL') {
            this.headers['X-banc'] = this.configService.baseConfig.environment;
        }
        if (this.oauth2.access_token) {
            this.headers.Authorization = `Bearer ${this.oauth2.access_token}`;
        }
        return {
            headers: new HttpHeaders(this.headers),
            params: this.params
        };
    }

    protected _getRequestUrl(): string {
        return this.localService ? this.configService.baseConfig.localUrl : this.configService.baseConfig.resourceUrl;
    }

    protected getInstance(): this {
        if (this.inService) {
            return this;
        } else {
            const instance: this = new (this.constructor as any)(
                this.oauth2,
                this.getClient(),
                this.configService,
                this.prismeService
            ) as this;
            instance.inService = true;
            return instance;
        }
    }

    protected getRequest(httpMethod: string, body?: BasicObject): Observable<any> {

        const http: HttpClient = this.http;
        let obs: Observable<any>;
        switch (httpMethod) {
            case 'POST':
                obs = http.post(this.getUrl(), body, this._getRequestOptions());
                break;

            case 'PUT':
                obs = http.put(this.getUrl(), body, this._getRequestOptions());
                break;

            case 'GET':
                obs = http.get(this.getUrl(), this._getRequestOptions());
                break;

            case 'DELETE':
                obs = http.delete(this.getUrl(), this._getRequestOptions());
                break;

            default:
                throw Error('Unknow HTTP method');
        }

        return obs.pipe(catchError((res: any) => {
            if (res.status === Oauth2ResourcesService.HTTP_UNAUTHORIZED) {
                return this.oauth2.login().pipe(mergeMap(() => obs));
            }

            throw res;
        }),
        this.retryOption ? retry(this.retryOption) : tap(),
        share({
            connector: () => new AsyncSubject(),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false
        }));
    }

    protected getResource(resourceUrl: string, ie11: boolean = false): this {
        const resource: this = this.getInstance();
        resource.resourceUrl += resourceUrl;
        resource.forceIE11 = ie11;

        const headers: BasicObject = {};
        headers['X-Message-Id'] = uuidv4();
        headers['X-Source'] = this.configService.baseConfig.xSource || 'MAGENTO';
        headers.Source = this.configService.baseConfig.xSource || 'MAGENTO'; // cas specifique
        resource.addHeaders(headers);

        return resource;
    }

    protected setTrackerId(): this {
        this.headers.TrackerId = this.prismeService.getSession();
        return this;
    }

    private _getRdv(url): this {
        return this.getResource(`/rendez-vous${url}`);
    }
}
