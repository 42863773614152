import { Component, Input } from '@angular/core';

@Component({
    selector: 'tlv-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    @Input() loadingText: string = 'Chargement en cours...';
    @Input() isAnimated: boolean = true;
    @Input() iconSize: string = 'small';
}
