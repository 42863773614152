import { Injectable } from '@angular/core';
import { AngularPrismeLogger } from '@bytel/prisme-logger/lib/angular';
import { BasicObject } from '@interfaces/utils.inteface';
import { ConfigurationService } from '../common-modules/services/configuration.service';
import { Oauth2Service } from '../common-modules/services/oauth2/oauth2.service';

@Injectable()
export class PrismeLoggerService {

    public constructor(
        private oauth2Service: Oauth2Service,
        private configService: ConfigurationService,
        protected angularPrismeLogger: AngularPrismeLogger
    ) {}

    public sendDataToPrisme(message: string, data?: any): void {
        if (!this.configService.data_refconf?.monitoring || !this.oauth2Service.jwtPayload){
            return;
        }

        const loggedUserData: BasicObject = {
            login: this.oauth2Service.jwtPayload.login,
            matricule: this.oauth2Service.jwtPayload.matricule,
            site: this.oauth2Service.jwtPayload.site,
            user_type: this.oauth2Service.jwtPayload.user_type
        };

        this.angularPrismeLogger.logToPrisme(
            message,
            {
                ...loggedUserData,
                ...data
            }
        );
    }

    public sendDataToPrismePromise(message: string, data?: any): Promise<void> {
        if (!this.configService.data_refconf?.monitoring || !this.oauth2Service.jwtPayload) {
            return Promise.resolve();
        }

        const loggedUserData: BasicObject = {
            login: this.oauth2Service.jwtPayload.login,
            matricule: this.oauth2Service.jwtPayload.matricule,
            site: this.oauth2Service.jwtPayload.site,
            user_type: this.oauth2Service.jwtPayload.user_type
        };

        return this.angularPrismeLogger.logToPrisme(
            message,
            {
                ...loggedUserData,
                ...data
            }
        );
    }
}
