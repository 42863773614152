import { Model } from '@models/model';
import { CONTRACT_STATUS } from '@interfaces/contract.interface';

export enum MARKET_LINE {
    FAI = 'FAI',
    FB = 'FB',
    FNB = 'FNB',
    PP = 'PP'
}

export class SignedContractModel extends Model{

    public marketLine: MARKET_LINE;
    public billingAccountId: string;
    public creationDate: Date;
    public status: CONTRACT_STATUS;
    public id: string;

    constructor(data: Partial<SignedContractModel>){
        super(data);
    }

}
