import { Model } from './model';

export class AddressPredictionModel extends Model {

    placeId: string;
    address: string;

    constructor(data: Partial<AddressPredictionModel>) {
        super(data);
    }


}
