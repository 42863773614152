import { USER_TITLES } from '../../constants/customer';
import { BillingAccountModel } from '@models/customer/billing-account.model';
import { Type } from 'class-transformer';
import { CustomerSearchModel } from '@models/customer/customer-search.model';

export class CustomerProSearchModel extends CustomerSearchModel {

    public birthDepartment: string;
    @Type(() => Date)
    public birthDate: Date;
    public firstname: string;
    public lastname: string;
    @Type(() => BillingAccountModel)
    public comptesFacturations: BillingAccountModel[];
    public idPerson: string;
    public gender: USER_TITLES;
    public email: string;
    public isClient: boolean;
    public idIdentity: string;
    public socialReason: string;
    @Type(() => Date)
    public creationDate: Date;
    public legalForm: string;
    public codeApeNaf: string;
    public siren: string;

    constructor(data: Partial<CustomerProSearchModel>){
        super(data);
    }
}
