import { Model } from '@models/model';

export class AddressValidationModel extends Model{

    postalCode?: string;
    city?: string;
    street?: string;
    streetNumber?: string;
    valid: boolean;

    constructor(data: Partial<AddressValidationModel>){
        super(data);
    }

}
