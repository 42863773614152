import { AddressModel } from '@models/cart/address.model';

export class BillingAddressModel extends AddressModel{

    public lastUpdate: Date;

    constructor(data: Partial<BillingAddressModel>) {
        super(data);
    }

}

