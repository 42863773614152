import { ConfigurationService } from './configuration.service';

export const configurationsProvider = (configurationService: ConfigurationService): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
        configurationService.getConfigurations().subscribe({
            next: () => resolve(true),
            error: () => resolve(false)
        });
    });

