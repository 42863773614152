import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BasicObject } from '../interfaces/services.interface';
import { Oauth2Service } from '../services/oauth2/oauth2.service';
import { ConfigurationService } from './configuration.service';
import { CookieStorageService } from './cookie-storage.service';
import { v4 as uuidv4 } from 'uuid';


class IPrismeLog {
    project: string;
    st: string;
    login: string;
    idPersonne: number;
    idContrat: number;
    nom: string;
    severite: string;
    message: string;
    enErreur: boolean;
    erreurType: string;
    erreurCode: number;
    erreurDescription: string;
    erreurOrigine: string;
    role: string;
    trackerId: string;
}

class IPrismeMap {
    RESSOURCE: string;
    MAGENTO: string;
}

// @dynamic
@Injectable({
    providedIn: 'root'
})
export class BtlPrismeService {

    private COOKIE_KEY: string = 'SESSION_PRISME';

    private _logStructure: IPrismeLog;
    private _sourceNameUrlMap: IPrismeMap;

    constructor(
        private oauth2: Oauth2Service,
        private config: ConfigurationService,
        private cookieStorage: CookieStorageService,
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document) {
        this._initialize(true);
    }

    /**
     * Bare settings for Tag service
     */
    public log(url: string, type: string, erreurCode: number, erreurDescription: string): Observable<any> {

        if (!erreurDescription) {
            erreurDescription = '';
        }

        const source: string = this._getSourceName(url);
        const data: BasicObject = {
            nom: `${source} - ${url.split('/').splice(3, 9999).join(' ').toUpperCase()}`,
            severite: type,
            message: url,
            erreurCode,
            erreurDescription: erreurDescription.replace('::', ':'),
            erreurOrigine: source,
            trackerId: this.getSession()
        };
        let returnValue: BasicObject = Object.assign({}, this._logStructure, data);

        if (this.oauth2.isConnected && this.oauth2.jwtPayload) {

            const loggedUserData: BasicObject = {
                login: this.oauth2.jwtPayload.login,
                role: this.oauth2.jwtPayload.user_type,
                idPersonne: this.oauth2.jwtPayload.id_personne,
                userType: this.oauth2.jwtPayload.user_type
            };

            returnValue = Object.assign(returnValue, loggedUserData);
        }

        return this._send(returnValue);
    }

    public getSession(): string {
        return this.cookieStorage.get(this.COOKIE_KEY);
    }

    private _send(data: BasicObject): Observable<any> {

        // Cette fonction semble inutilisée par la TLV => prévoir un clean
        if (!this.config.data_refconf.prisme.status || !this.config.data_refconf.prisme.js_error ||
            !this.config.data_refconf.prisme.url) {
            return;
        }

        let stringValue = '';

        for (const key in data) {
            if (data[key]) {
                if (stringValue !== '') {
                    stringValue += '::';
                }
                stringValue += `${key}=${data[key]}`;
            }
        }

        const queryString = new HttpParams().set('info', btoa(stringValue));
        const httpOptions = {
            params: queryString
        };

        return this.http.get(this.config.data_refconf.prisme.url, httpOptions).pipe(
            catchError((error) => {
                console.error(error);
                return of(null);
            }));
    }

    private _getSourceName(url: string): string {

        if (!url) {
            return 'MAGENTO';
        }
        for (const key in this._sourceNameUrlMap) {
            if (url.includes(this._sourceNameUrlMap[key])) {
                return key;
            }
        }
        return 'AUTRE';
    }

    private _initialize(reset: boolean = false): void {
        const windowDom: Window = this.document.defaultView.window;
        (windowDom as BasicObject).prisme = this;

        this._logStructure = {
            project: 'MAGENTO_FO',
            st: 'MAGENTO_FO',
            login: '',
            idPersonne: null,
            idContrat: null,
            nom: '',
            severite: '',
            message: '',
            enErreur: true,
            erreurType: 'Technique',
            erreurCode: null,
            erreurDescription: '',
            erreurOrigine: '',
            role: '',
            trackerId: ''
        };

        this._sourceNameUrlMap = {
            RESSOURCE: this.config.baseConfig.localUrl,
            MAGENTO: windowDom.location.origin
        };

        if (!this.getSession() || reset) {
            const expire = new Date();
            expire.setDate(expire.getDate() + 1);
            this.cookieStorage.set(
                this.COOKIE_KEY,
                uuidv4(),
                expire,
                '/',
                `.${windowDom.location.hostname}`,
                windowDom.location.protocol.includes('https')
            );
        }
    }
}
