import { ContactInformationsModel } from '@models/customer/contact-informations.model';
import { CustomerProModel } from '@models/customer/customer-pro.model';
import { CompanyModel } from '@models/customer/company.model';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { AddressModel } from '@models/cart/address.model';
import { Type } from 'class-transformer';

export class CustomerProDetailsModel extends CustomerProModel {

    public email: string;
    public phone: string;
    @Type(() => AddressModel)
    public address: AddressModel;
    @Type(() => CompanyModel)
    public company: CompanyModel;

    constructor(customer: Partial<CustomerProDetailsModel>,public contactInformation?: ContactInformationsModel){
        super(customer);
        if (contactInformation){
            this.phone = contactInformation.mainPhone?.phoneNumber;
            this.email = contactInformation.mainEmail?.email;
        }
        if (customer && this.company) {
            this.birthDate = this.company.legalRepresentative.birthDate || customer.birthDate;
            this.firstname = this.company.legalRepresentative.firstName || customer.firstname;
            this.lastname = this.company.legalRepresentative.lastName || customer.lastname;
            this.gender = this.company.legalRepresentative.gender || customer.gender;
        }
    }

    public static GetIntanceFromCustomerAndCompany(
        customer: CustomerProGpDetailsModel,
        company: CompanyModel
    ): CustomerProDetailsModel {
        return new CustomerProDetailsModel({
            ...customer,
            company,
            address: company.address,
        });

    }



}
