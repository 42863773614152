import { OrderModel } from '@models/order/order.model';

export class ProposalModel extends OrderModel{

    public validityDate: Date;
    public amount: number;
    public monthlyAmount: number;

    constructor(data: Partial<ProposalModel>){
        super(data);
        this.monthlyAmount = +this.offer.reduce((acc, offer)=>
            acc
            + offer.elementsCommandes
                .filter((elCommande)=>elCommande.tarifsCommerciaux)
                .reduce((accEl,elCommande)=>
                    accEl + elCommande.tarifsCommerciaux.filter((tarif)=>tarif.typeTarif === 'ABONNEMENT')
                        .reduce((accTarif,tarif)=>accTarif + tarif.montantTTC,0)
                , 0)
            + offer.elementsCommandes
                .filter((elCommande)=>elCommande.type === 'ETALEMENT_PAIEMENT')
                .reduce((accEl,elCommande)=> accEl + elCommande.montantPrelevement, 0)
        ,0).toFixed(2);
    }

}
