import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { catchError, share, tap } from 'rxjs/operators';
import { BasicObject } from '../interfaces/services.interface';
import {PrismeLoggerService} from '@services/prisme-logger.service';

@Injectable()
export class CacheHttpInterceptor implements HttpInterceptor {
    protected cache: BasicObject = {};

    public constructor(
        private injector: Injector,
        private prismeLoggerService: PrismeLoggerService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let cache: boolean = false;
        let key: string = '';

        const cacheHeader: string = request.headers.get('Cache-Control');
        if (cacheHeader && cacheHeader.includes('cache-js')) {
            cache = true;

            const params: string[] = [
                request.urlWithParams,
                JSON.stringify(request.body)
            ];
            key = this._getHashCode(params);

            // Si header + cache existant
            if (this.cache[key]) {
                return this.cache[key];
            }
        }

        // Pas de cache
        this.cache[key] = next.handle(request).pipe(
            tap(res => {
                if (cache && res instanceof HttpResponse) {
                    if (res.status >= 300) {
                        const retour = JSON.parse(JSON.stringify(res.clone()));
                        delete retour?.headers;
                        delete retour?.body;
                        this.prismeLoggerService.sendDataToPrisme('Erreur applicative', {error:retour});
                    }
                    // Mise en cache
                    if (res.body) {
                        this.cache[key] = of(res);
                    } else {
                        this.cache[key] = null;
                    }
                }
            }),
            catchError((err) => {
                this.cache[key] = null;
                delete err?.error?.stack;
                this.prismeLoggerService.sendDataToPrisme('Erreur HTTP', {error:err});
                return throwError(err);
            }),
            share()
        );
        return this.cache[key];
    }

    private _getHashCode(params: string[]): string {
        if (params != null && params.length !== 0) {
            return this._stringToHash(JSON.stringify(params)).toString();
        }
    }

    private _stringToHash(str: string): number {
        let hash: number = 5381;
        let i: number = str.length;
        while (i) {
            // eslint-disable-next-line no-bitwise
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }
        /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
         * integers. Since we want the results to be always positive, convert the
         * signed int to an unsigned by doing an unsigned bitshift. */
        // eslint-disable-next-line no-bitwise
        return hash >>> 0;
    }
}
