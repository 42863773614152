import { IFaiAppointmentUpdatedParams } from '@interfaces/api/appointment/appointmentInput';

import { SlotModel } from './slot.model';

export class AppointmentModel extends SlotModel {

    public comment?: string;
    public digicode?: string;
    public interphone?: string;
    public contactNumber?: string;
    public disponibilitySlot?: string;
    public isPremuim: boolean;
    public dataToUpdate: IFaiAppointmentUpdatedParams;

    constructor(data: Partial<AppointmentModel>) {
        super(data);
    }

}
