import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    DATA = 'data',
}

export class SalesforceStorage extends StorageData{

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix: string = 'salesforce';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
