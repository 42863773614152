<div class="hero is-instit is-centered">
    <div class="hero-body columns is-marginless">
        <h1 class="has-text-white has-text-left title column">
            <span class="icon-and-text">
                <span class="icon is-medium">
                <i [ngClass]="iconName"></i>
                </span>
                <span>{{title}}</span>
            </span>
        </h1>
        <div *ngIf="isClosable" class="has-text-white has-text-right column is-4">
            <a class="link is-plain" (click)="onClickAction()" >
                <span class="icon is-large"><i class="tri-times"></i></span>
            </a>
        </div>
    </div>
</div>