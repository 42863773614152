import { Injectable } from '@angular/core';
import {ConfigurationService, Oauth2ResourcesService} from '@common-modules';
import { IFaiStructureVerticale } from '@interfaces/api/fai-webshop.interface';
import {
    ApiNumberLogins,
    ApiVoipNumbers,
    IFaiEligibilityResultat,
    IFaiLogmentResult,
    IFaiResultat,
    IFaiTechnoEligibility,
    IFaiBox5gResult,
    IFaiBoxResult,
    LogementOutput,
    IFaiStructureVerticaleOut,
    IEligibilityAddressOut
} from '@interfaces/api/fai.interface';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { FaiAddressModel } from '@models/fai/fai-address';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { FaiEligibilityModel } from '@models/fai/fai-eligibility.model';
import { FaiLogementModel } from '@models/fai/fai-logement.model';
import { FaiLoginsAndVoipsModel } from '@models/fai/fai-portability-logins-voips.model';
import { FaiTechnoModel } from '@models/fai/fai-techno.model';
import { FaiVerticalStructureModel } from '@models/fai/Fai-vertical-structure.model';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import {
    FAI_CART_TECHNO,
    FAI_CART_TYPE,
    FAI_ELIGIBILITY_STATES,
    FAI_ELIGIBILITY_STATUS,
    FAI_TECHNO,
} from '../constants/fai';
import bind from '../helper/decorators/bind';
import { DisplayError } from '../models/error/display.error';
import { ClientFaiError } from '@repositories/error/client-fai.error';
import { NotEligibleFaiError } from '@repositories/error/not-eligible-fai.error';

@Injectable({
    providedIn: 'root'
})
export class FaiRepository{

    constructor(private oauth2Ressource: Oauth2ResourcesService, private config: ConfigurationService) {}

    public funnel(cart: FaiCartModel, address: { postalCode?: string; cityCode?: string;streetCode?: string }, search?: string
    ): Observable<{
            codeNumeroVoie: {code: string;libelle: string}[];
            codeVoie: {code: string;libelle: string}[];
            codePostal: string[];
            extensions: {code: string;libelle: string}[];
            codeInsee: {code: string;libelle: string}[];
        }> {
        let request = this.oauth2Ressource.entonnoir(cart.id).techno(cart.techno).addCache();
        if (address.postalCode){
            request = request.postalCode(address.postalCode);
        }
        if (address.cityCode){
            request = request.codeInsee(address.cityCode);
            if (search && !address.streetCode){ // search street
                request = request.setParams({pattern:search});
            }
        }
        if (address.streetCode){
            request = request.codeVoie(address.streetCode);
            if (search && !address.cityCode){ // search complement
                request = request.setParams({index:search});
            }
        }
        return request.get();
    }

    public getPto(
        cartId: string,
        address: FaiAddressModel,
        techno: FAI_CART_TECHNO
    ): Observable<FaiVerticalStructureModel[]> {
        return this.oauth2Ressource
            .entonnoir(cartId)
            .techno(techno)
            .postalCode(address.postal)
            .codeInsee(address.city.code)
            .codeVoie(address.street.code)
            .codeNumeroVoie(address.streetNumberCode)
            .get()
            .pipe(
                map((data: any) => {
                    if (!data.structureVerticale || !data.structureVerticale.length || data.maxItem === 0) {
                        throw new DisplayError('Aucun logement n\'est remonté, veuillez passer par une éligibilité Multitechno.');
                    }
                    return data.structureVerticale.map(this._createVerticalStructure);
                })
            );
    }

    public getEligibility(
        cart: FaiCartModel,
        logement: FaiLogementModel,
        verticalStructure: FaiVerticalStructureModel
    ): Observable<FaiEligibilityModel>{
        return forkJoin({
            box: logement && cart.techno === FAI_CART_TECHNO.MULTI_TECHNO ?
                this._getBoxEligibility(cart.searchAddress || logement) : of(null),
            webShop: this._getWebShopEligibility(cart,logement,verticalStructure)
        }).pipe(
            map(({ box, webShop }) => this._createFaiEligibity(webShop, box)),
            tap(elig=>{
                if (elig.getOCs().length === 0){
                    throw new NotEligibleFaiError();
                }
            }),
            mergeMap((elig: FaiEligibilityModel) => {
                if (elig?.ftth.status === FAI_ELIGIBILITY_STATUS.NOK){
                    return this._getForecastFtthEligibility(cart.searchAddress || logement).pipe(
                        tap(res => elig.ftth.allowForecast = res),
                        map(()=>elig)
                    );
                }
                return of(elig);
            })
        );
    }

    public createCart(type: FAI_CART_TYPE, techno: FAI_CART_TECHNO = FAI_CART_TECHNO.MULTI_TECHNO): Observable<FaiCartModel>{
        return this.oauth2Ressource
            .eligibiliteFai()
            .setParams({...(type ? {parcours: type} : {})})
            .post({
                ...this._getContextRequest(techno)
            }).pipe(map((data)=>new FaiCartModel(type,techno,data.idPanier)));
    }

    public getLogements(cart: FaiCartModel, nameFilter?: string): Observable<FaiLogementModel[]>{
        let request: Observable<IFaiResultat<IFaiLogmentResult>>;
        if (cart.type === FAI_CART_TYPE.ADDRESS){
            request = this.oauth2Ressource.addCache().eligibiliteFai(cart.id).techno(cart.techno).put({
                ...this._getContextRequest(cart.techno),
                adresse: {
                    codePostal: cart.searchAddress.postal,
                    codeInsee: cart.searchAddress.city.code,
                    codeVoie: cart.searchAddress.street.code,
                    numeroVoie: cart.searchAddress.street_number,
                    extension: cart.searchAddress.complementCode,
                    ...(cart.searchAddress.streetNumberCode ?  {codeNumeroVoie:cart.searchAddress.streetNumberCode} : {})
                },
                ...(nameFilter !== undefined ? {identite: {nom: nameFilter}} : {})
            });
        } else if (cart.type === FAI_CART_TYPE.ND){
            request = this.oauth2Ressource.addCache().eligibiliteFai(cart.id).nd(cart.searchNumber).put({
                nse: {nse:cart.searchNumber, statut: cart.option?.status ? 'ACTIF' : 'INACTIF'}
            });
        } else {
            throw new Error('FaiCart is incompatible with search logement');
        }
        return request.pipe(
            map((result)=>
                result.resultat?.logements?.logements.map((logement)=>new FaiLogementModel({...logement,logementNonTrouve: false})) ?? []),
            catchError((err) => {
                throw new DisplayError(err.error?.msgRetour);
            })
        );
    }

    public getConvergenceForOffers(gencodes: string[]): Observable<{[index: string]: boolean}> {
        return this.oauth2Ressource.useSalesApi().ventes().listerAvantages().post({
            offresPrincipalesPanier: [],
            offresPrincipalesProposees: gencodes.map((gencode) => ({idOffre: gencode, typeContexteContractuel: 'ACQUISITION'}))
        }).pipe(
            catchError(() => of({})),
            map((data: {
                offresPrincipalesProposees: {
                    idOffre: string;
                    niveauGammeConvergent: string;
                    typeContexteContractuel: string;
                }[];
            })=>gencodes.reduce((acc,gencode)=>{
                // eslint-disable-next-line max-len
                acc[gencode] = data?.offresPrincipalesProposees?.find((offer)=>offer.idOffre === gencode).niveauGammeConvergent === 'PREMIUM';
                return acc;
            },
            {}))
        );
    }

    public getFaiPortabilityParams(cartId: string, customer: CustomerProGpDetailsModel): Observable<FaiLoginsAndVoipsModel> {

        const voipNumbers$: Observable<ApiVoipNumbers> = this.oauth2Ressource
            .eligibilite(cartId)
            .voips()
            .setParams({
                nbNumVoip: 5,
                type: 'VOIP'
            })
            .get();
        const logins$: Observable<ApiNumberLogins> = this.oauth2Ressource
            .eligibilite(cartId)
            .loginReservation()
            .setParams({
                nbLogin: 3,
                nom: customer.lastname,
                prenom: customer.firstname
            })
            .get();

        return voipNumbers$.pipe(
            mergeMap((voipNumbers) => logins$.pipe(
                map((logins) => new FaiLoginsAndVoipsModel({
                    voipNumbers: voipNumbers?.voips.map(vNumber => ({voipNumber: vNumber.numVoip, type: vNumber.type})),
                    logins: logins?.logins,
                    cartId
                })))
            )
        );
    }

    private _getBoxEligibility(address: FaiAddressModel | FaiLogementModel): Observable<IFaiBoxResult> {
        return this.oauth2Ressource.eligibiliteTokyo().post(
            (address instanceof FaiAddressModel ? {
                numeroVoie: address.street_number,
                codeInsee: address.city.code,
                codeRivoli: address.street.code
            } : {
                numeroVoie: address.numeroVoie,
                codeInsee: address.codeInsee,
                codeRivoli: address.codeVoie
            })
        ).pipe(
            catchError(() => of(null))
        );
    }

    private _createFaiEligibity(
        webShop: IFaiResultat<IFaiEligibilityResultat>,
        box: IFaiBoxResult
    ): FaiEligibilityModel {

        return new FaiEligibilityModel({
            installationAddress: new FaiAddressModel({
                postal: webShop.resultat.adresseInstallation?.codePostal,
                city: {
                    libelle: webShop.resultat.adresseInstallation?.commune,
                    code: webShop.resultat.adresseInstallation?.codeInsee,
                },
                street: {
                    libelle: webShop.resultat.adresseInstallation?.voie,
                    code: webShop.resultat.adresseInstallation?.codeVoie
                },
                street_number: webShop.resultat.adresseInstallation?.numeroVoie,
                complement: webShop.resultat.adresseInstallation?.extension,
            }),
            webshopData: webShop,
            isCDL: webShop.resultat.typeEligibilite === 'CDL',
            vdsl: this._createWebShopTechnoModel(FAI_TECHNO.VDSL, webShop.resultat.vdsl,
                webShop.resultat.ftth.eligibilite === FAI_ELIGIBILITY_STATES.ELIGIBLE),
            adsl: this._createWebShopTechnoModel(FAI_TECHNO.ADSL, webShop.resultat.adsl,
                webShop.resultat.ftth.eligibilite === FAI_ELIGIBILITY_STATES.ELIGIBLE),
            ftth: this._createWebShopTechnoModel(FAI_TECHNO.FTTH, webShop.resultat.ftth),
            box_4g: box ? this._createBoxTechnoModel(FAI_TECHNO.BOX_4G, box) : null,
            box_5g: box?.eligibilite5gBox ? this._createBoxTechnoModel(FAI_TECHNO.BOX_5G, box) : null
        });
    }

    private _getForecastFtthEligibility(
        logement: FaiLogementModel | FaiAddressModel,
    ): Observable<{date: string; eligible: boolean}> {
        return this.oauth2Ressource
            .eligibilitesPrevisionnelles(logement instanceof FaiLogementModel ? logement.codeInsee : logement.city.code)
            .setParams({listeNomsProgrammes: 'FTTH'}).get().pipe(
                map(res => ({
                    date: res?.eligibilitePrevisionnelle[0]?.jalons[0]?.date,
                    eligible: res?.eligibilitePrevisionnelle?.length > 0
                })),
                catchError(()=>of(null))
            );
    }

    private _createWebShopTechnoModel(type: FAI_TECHNO, apiData: IFaiTechnoEligibility, forceKo: boolean = false): FaiTechnoModel{
        return new FaiTechnoModel({
            status: forceKo ? FAI_ELIGIBILITY_STATUS.NOK : this._getStatus(apiData.eligibilite),
            type,
            debitUpMax: apiData.debitUpMax,
            debitUpMin: apiData.debitUpMin,
            debitDownMax: apiData.debitDownMax,
            debitDownMin: apiData.debitDownMin,
            technologiesPon: apiData.ponTechnologies,
            offres: apiData.offres.map(offre=>offre.toString()),
            ...(type === FAI_TECHNO.FTTH ? {
                structureVerticales: apiData.structureVerticales.map(this._createVerticalStructure)} :
                {}
            )
        });
    }

    private _createBoxTechnoModel(type: FAI_TECHNO, apiData?: IFaiBoxResult): FaiTechnoModel {
        if (type === FAI_TECHNO.BOX_4G) {
            return new FaiTechnoModel({
                status: apiData && apiData?.eligibiliteTokyo ? FAI_ELIGIBILITY_STATUS.OK : FAI_ELIGIBILITY_STATUS.NOK,
                type,
                homeZone: apiData?.homeZone,
                debitUpMax: 38,
                debitUpMin: 38,
                debitDownMax: 115,
                debitDownMin: 115,
                allowForecast: {eligible: !apiData?.eligibiliteTokyo},
                offres: apiData?.idOffres || []
            });
        }
        if (type === FAI_TECHNO.BOX_5G) {
            const apiData_box5g: IFaiBox5gResult = apiData.eligibilite5gBox;
            return new FaiTechnoModel({
                status: apiData_box5g && apiData_box5g?.eligibilite5gBox ? FAI_ELIGIBILITY_STATUS.OK : FAI_ELIGIBILITY_STATUS.NOK,
                type,
                homeZone: apiData_box5g?.homeZone,
                debitUpMax: 58,
                debitUpMin: 58,
                debitDownMax: 1100,
                debitDownMin: 1100,
                allowForecast: {eligible: !apiData_box5g?.eligibilite5gBox},
                offres: apiData_box5g?.idOffres || []
            });
        }
    }

    @bind
    private _createVerticalStructure(data: IFaiStructureVerticale): FaiVerticalStructureModel{
        return new FaiVerticalStructureModel({
            batiment: data.batiment,
            escalier: data.escalier,
            etage: data.etage,
            presencePto: data.presencePto,
            ptos: data.ptos,
            idPtoObligatoire: data.idPtoObligatoire,
            dateOuvertureCommerciale: data.dateOuvertureCommerciale?.replace(/\Z.*/,'Z')
        });
    }

    private _getStatus(status: string): FAI_ELIGIBILITY_STATUS{
        switch (status){
            case 'EN_CONSTRUCTION': return FAI_ELIGIBILITY_STATUS.CONSTRUCTION;
            case 'ELIGIBLE': return FAI_ELIGIBILITY_STATUS.OK;
            case 'NON_ELIGIBLE':
            default:
                return FAI_ELIGIBILITY_STATUS.NOK;
        }
    }

    private _getContextRequest(techno: string): any{
        return {
            contexte: {
                canal: 'Magento',
                ihm: 'API_MAGENTO',
                operateur: 'Magento',
                parcours: '',
                typeProspect: 'GP_PRO',
                technoCible: techno
            }
        };
    }

    private _generateDataEligibilityAddress(
        verticalStructure: FaiVerticalStructureModel,
        logement: FaiLogementModel,
        cart: FaiCartModel): IEligibilityAddressOut {
        return {
            adresse: {
                ...(verticalStructure ? this._getVerticalStructure(verticalStructure) : {}),
                ...(logement && !logement.logementNonTrouve ? this._getLogement(logement) : this._getLogementByCart(cart))
            }
        };
    }

    private _getVerticalStructure(verticalStructure: FaiVerticalStructureModel): IFaiStructureVerticaleOut {
        return {
            batiment: verticalStructure.batiment,
            escalier: verticalStructure.escalier,
            etage: verticalStructure.etage,
            presencePto: verticalStructure.presencePto,
            ...(verticalStructure.ptoSelected ? {pto: verticalStructure.ptoSelected} : {})
        };
    }

    private _getLogement(logement: FaiLogementModel): LogementOutput {
        return {
            codeInsee: logement?.codeInsee,
            codeNumeroVoie: logement?.codeNumeroVoie,
            codePostal: logement?.codePostal,
            codeVoie: logement?.codeVoie,
            numeroVoie: logement?.numeroVoie
        };
    }

    private _getLogementByCart(cart: FaiCartModel): LogementOutput {
        return {
            codeInsee: cart.searchAddress.city.code,
            codeNumeroVoie: cart.searchAddress.streetNumberCode,
            codePostal: cart.searchAddress.postal,
            codeVoie: cart.searchAddress.street.code,
            numeroVoie: cart.searchAddress.street_number
        };
    }

    private _generateDataEligibilityNumber(cart: FaiCartModel,
                                           verticalStructure: FaiVerticalStructureModel): {nse: {nse: string; statut: string}} {
        return {
            nse: {nse: cart.searchNumber, statut: cart.option?.status ? 'ACTIF' : 'INACTIF'},
            ...(verticalStructure ? {adresse: this._getVerticalStructure(verticalStructure)} : {})
        };
    }

    private _getWebShopEligibility(
        cart: FaiCartModel,
        logement: FaiLogementModel,
        verticalStructure: FaiVerticalStructureModel
    ): Observable<IFaiResultat<IFaiEligibilityResultat>>{
        let request = this.oauth2Ressource.eligibiliteFai(cart.id);
        if (cart.type === FAI_CART_TYPE.ADDRESS){
            request = request.techno(cart.techno);
        } else if (cart.type === FAI_CART_TYPE.ND){
            request = request.nd(cart.searchNumber);
        }
        return request.put<IFaiResultat<IFaiEligibilityResultat>>(
            {
                ...this._getContextRequest(cart.techno),
                ...(cart.type === FAI_CART_TYPE.ADDRESS ?
                    this._generateDataEligibilityAddress(verticalStructure, logement, cart)
                    : this._generateDataEligibilityNumber(cart,verticalStructure)),
                ...(logement ? {
                    logement: logement.logementNonTrouve ?
                        {logementNonTrouve: true, ...logement} :
                        {codeLogementSelectionne: logement.codeLogement, libelle: ''}
                } : {})
            }
        ).pipe(catchError((data)=>{
            if (data.error.codeRetour === 'F29001'){
                throw new ClientFaiError();
            }
            throw new Error();
        }));
    }
}
