import { ResponseFeature } from '@interfaces/mobile-recovery-interface';
import { Model } from '@models/model';

export class FeatureMobileRecoveryModel extends Model{


    id: string;
    label: string;
    options: string[];
    responses: ResponseFeature[];

    constructor(data: Partial<FeatureMobileRecoveryModel>) {
        super(data);
    }

}
