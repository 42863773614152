import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import {
    CountableResult,
    CritereRepriseParProduit,
    CriteresRepriseProduit,
    DevisRepriseMobileIn,
    MOBILE_RECOVERY_STATUS,
    PartnerProduct,
} from '@interfaces/mobile-recovery-interface';
import { FeatureMobileRecoveryModel } from '@models/cart/feature-mobile-recovery.model';
import { MobileRecoveryModel, MobileRecoveryPartnerDataModel } from '@models/cart/mobile-recovery.model';
import { SurveyMobileRecoveryModel } from '@models/cart/quote-mobile-recovery.model';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import bind from '../helper/decorators/bind';
import { CheckoutStorage } from './storages/checkout.storage';

@Injectable({
    providedIn: 'root'
})
export class MobileRecoveryRepository {

    constructor(
        private oauth2Resource: Oauth2ResourcesService,
        private readonly httpClient: HttpClient
    ) {}

    public getPartnerProducts(libelle: string): Observable<MobileRecoveryModel[]> {
        return this.oauth2Resource
            .useSalesApi()
            .produitsPartenaires()
            .setParams({libelle})
            .get()
            .pipe(
                map((result: CountableResult<PartnerProduct>) => result.items.slice(0,10)
                    .map((product: PartnerProduct) =>
                        new MobileRecoveryModel({
                            id: product.id,
                            name: product.nom,
                            partnerData: new MobileRecoveryPartnerDataModel({
                                minAmount: product.montantMin,
                                maxAmount: product.montantMax,
                                status: MOBILE_RECOVERY_STATUS.UNSTARTED
                            }),
                            brand: product.marque
                        }))
                ));
    }

    public getPartnerProductFeatures(idPhone: number): Observable<FeatureMobileRecoveryModel[]> {
        return this.oauth2Resource
            .useSalesApi()
            .produitsPartenaires(idPhone.toString())
            .get()
            .pipe(map(this._mapToFeatureOptions));
    }

    public getQuotePartner(mobileRecoveryInfo: MobileRecoveryModel): Observable<DevisRepriseMobileIn> {
        return this.oauth2Resource
            .useSalesApi()
            .produitsPartenaires(mobileRecoveryInfo.id.toString(), true)
            .post({
                criteresReprise: mobileRecoveryInfo.partnerData.surveys.map((survey) => classToPlain(new SurveyMobileRecoveryModel(survey)))
            });
    }

    public pushMobileRecovery(mobileRecoveryInfo: MobileRecoveryModel, url: string): Observable<any> {
        return this.httpClient.post(
            url,
            this._prepareMobileRecovery(mobileRecoveryInfo),
            { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
        ).pipe(
            map((data: {recovery_id: string; message: string; error: []}) => data.recovery_id)
        );
    }

    public save(mobileRecoveryInfo: MobileRecoveryModel): void{
        CheckoutStorage.setItem(CheckoutStorage.KEYS.MOBILE_RECOVERY,classToPlain(mobileRecoveryInfo));
    }

    public delete(): void{
        CheckoutStorage.removeItem(CheckoutStorage.KEYS.MOBILE_RECOVERY);
    }

    public getCurrentMobileRecovery(): MobileRecoveryModel{
        const data = CheckoutStorage.getItem(CheckoutStorage.KEYS.MOBILE_RECOVERY);
        if (data){
            return plainToClass(MobileRecoveryModel,data);
        }
        return new MobileRecoveryModel({});
    }

    private _prepareMobileRecovery(mobileRecoveryInfo: MobileRecoveryModel): any {
        const recoveryData = {
            recovery_insurance: (Number(mobileRecoveryInfo.partnerData.insuranceAmount) * -1) > 0 ? 1 : 0,
            recovery_amount: mobileRecoveryInfo.prices.base,
            recovery_imei: undefined, // value not return from ressource
            recovery_bonus: mobileRecoveryInfo.prices.base < 10 ? 0 : mobileRecoveryInfo.partnerData.bonus,
            recovery_product_id: mobileRecoveryInfo.id,
            recovery_quotation_id: mobileRecoveryInfo.partnerData.quoteId,
            recovery_name: mobileRecoveryInfo.name,
            recovery_brand: mobileRecoveryInfo.brand,
            recovery_terms_of_sales:  true,
        };
        mobileRecoveryInfo.partnerData.surveys.forEach((survey: SurveyMobileRecoveryModel, index: number) =>{
            recoveryData[`recovery_questions[${index}]`] = JSON.stringify(survey);
        });
        let params = new HttpParams();
        Object.keys(recoveryData).forEach((key) => params = params.append(key, recoveryData[key]));
        return params;
    }

    @bind
    private _mapToFeatureOptions(result: CritereRepriseParProduit): FeatureMobileRecoveryModel[] {
        return result.criteresRepriseProduit.map((critereRepriseProduit: CriteresRepriseProduit) => new FeatureMobileRecoveryModel({
            id: critereRepriseProduit.id,
            label: critereRepriseProduit.libelle,
            responses: critereRepriseProduit.reponses.map((respo) => ({
                id: respo.id,
                label: respo.libelle,
                description: respo.description
            }))
        }));
    }

}


