import { IFaiComplement } from '@interfaces/api/fai-webshop.interface';
import { AddressValidationModel } from '@models/validators/address-validation.model';

export class AddressModel extends AddressValidationModel{

    public complement?: string;
    public lat?: number;
    public lng?: number;
    public confirmed: boolean;
    public creationDate?: Date;

    constructor(data: Partial<AddressModel>) {
        super(data);
        if (!data){return;}
        this.valid = data.valid || false;
        this.confirmed = data.confirmed || false;
    }

    public getInline(withComplement: boolean = true): string {
        const complement: string = typeof this.complement !== 'string' ?
            (this.complement as unknown as IFaiComplement)?.libelle : this.complement;
        // eslint-disable-next-line max-len
        return `${this.streetNumber || ''}${this.complement && withComplement ? ' ' + complement + ' ' : ' '}${this.street}, ${this.postalCode} ${this.city}`;
    }

    public isSameAddress(address: AddressModel): boolean {
        return address.getInline(true) === this.getInline(true);
    }

}

