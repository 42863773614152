import { Injectable } from '@angular/core';
import { ConfigurationService } from '../common-modules/services/configuration.service';
import { Oauth2Service } from '../common-modules/services/oauth2/oauth2.service';

export enum CLUSTER {
    ENTRANT = 'ENTRANT',
    SORTANT = 'SORTANT',
    PARTENAIRE_WEB = 'PARTENAIRE_WEB',
    POLE_ACTIVATION = 'POLE_ACTIVATION',
    DAD = 'DAD',
    BANQUE = 'BANQUE',
    TRIGGER = 'TRIGGER',
    BLANCHE = 'BLANCHE',
    PILOTE_PANIERMIXTE = 'PILOTE_PANIERMIXTE',
    TESTBED = 'TESTBED',
    TLV = 'TLV',
}

@Injectable({
    providedIn: 'root'
})
export class ClusterService {

    constructor(
        private configService: ConfigurationService,
        private oauth2Service: Oauth2Service
    ) { }

    public getUserClusters(): string[] {
        const allClusters = this.configService.data_refconf.clusters;
        const userSite = this.oauth2Service.jwtPayload.site;
        return Object.keys(allClusters)?.filter(key => allClusters[key]?.includes(userSite));
    }

    public userIsInCluster(cluster: CLUSTER): boolean {
        const allClusters = this.configService.data_refconf.clusters;
        const userSite = this.oauth2Service.jwtPayload.site;
        return allClusters[cluster]?.includes(userSite);
    }

}
