import { AppointmentModel } from '@models/cart/appointment.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { Model } from '@models/model';
import { Type } from 'class-transformer';
import { OrderDeliveryInfoModel } from './order-delivery.model';
import { OrderPaymentInfoModel } from './order-payment-info.model';

export class OrderCartInfoModel extends Model {

    public id: string;
    public accessories: {name: string; gencode: string}[];
    public options: {name: string; gencode: string}[];
    @Type(()=>PortabilityModel)
    public portability?: PortabilityModel;
    @Type(()=>AppointmentModel)
    public appointment?: AppointmentModel;
    @Type(()=>OrderDeliveryInfoModel)
    public delivery: OrderDeliveryInfoModel;
    @Type(()=>OrderPaymentInfoModel)
    public payment: OrderPaymentInfoModel;
    public restoredProductsTypes: string[] = [];

    constructor(data: Partial<OrderCartInfoModel>) {
        super(data);
    }

    public canRestoreProduct(gencode: string): boolean {
        return !this.restoredProductsTypes.includes(gencode);
    }

    public logRestoredProduct(typeId: string): void {
        const allValues: string[] = [].concat(...this.restoredProductsTypes, typeId);
        this.restoredProductsTypes = [...new Set(allValues)];
    }

}
