import { USER_TITLES } from '../../constants/customer';
import {  CONTRACT_STATUS } from '@interfaces/contract.interface';
import { Model } from '../model';
import { BillingAccountModel } from '@models/customer/billing-account.model';

export class CustomerSearchModel extends Model {

    public birthDepartment: string;
    public birthDate: Date;
    public firstname: string;
    public lastname: string;
    public comptesFacturations: BillingAccountModel[] = [];
    public idPerson: string;
    public gender: USER_TITLES;
    public email: string;
    public phone: string;
    public isClient: boolean;
    public idIdentity: string;

    constructor(data: Partial<CustomerSearchModel>){
        super(data);
        this.comptesFacturations = data?.comptesFacturations || [];
        this.isClient = this.comptesFacturations.some((compteFacturation) =>
            compteFacturation.contratsPayes.some((contract) =>
                [
                    CONTRACT_STATUS.ACTIVE,
                    CONTRACT_STATUS.SUSPENDED
                ].includes(contract.statut as CONTRACT_STATUS)
            )
        );
    }
}
