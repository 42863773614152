import { Pipe, PipeTransform } from '@angular/core';

enum SimTypesLabels {
    sim = 'SIM',
    default = 'SIM',
    esim = 'eSIM',
    hybride = 'SIM & eSIM'
};

@Pipe({
    name: 'simTypeLabel'
})
export class SimTypeLabelPipe implements PipeTransform {

    public transform(type: string): string {
        return SimTypesLabels[type];
    }

}
