import { BasicObject } from './services.interface';
import { GMapsMarkerIcon } from './bytel-gmaps.interface';

export interface Store {
    id?: string;
    name?: string;
    listIndex?: number;
    distanceFromAdress?: number;
    coordinates: Coordinates;
    street: string;
    street_number?: number;
    city: string;
    postalCode: number;
    country: string;
    hours: StoreHours;
    icons: GMapsMarkerIcon;
    deliveryType?: string;
    storeType?: string;
    isDeliveryAddress?: boolean;
}

export interface StoreHours {
    exceptionalClosings: string[];
    weekly: BasicObject;
}

export interface Coordinates {
    lat: number;
    lng: number;
}

export enum MAP_LOCATION {
    LEFT = 'left',
    RIGHT = 'right'
}

export enum TAB_CHOICE {
    STORES = 'STORES',
    MAP = 'MAP'
}
