import { Model } from './model';

export class PaymentResponseModel extends Model {

    public redirectUrl: string;
    public formDataToPost: {
        method: string;
        url: string;
        inputs?: {name: string; value: string}[];
    };

    constructor(data: Partial<PaymentResponseModel>) {
        super(data);
    }
}
