import { CustomerModel } from '@models/customer/customer.model';
import { Type } from 'class-transformer';
import { CompanyModel } from '@models/customer/company.model';

export class CustomerProModel extends CustomerModel {

    @Type(() => CompanyModel)
    public company: CompanyModel;

    constructor(data: Partial<CustomerProModel>){
        super(data);
    }
}
