import { Injectable } from '@angular/core';
import { Phone } from '@bytel/bytel-sales';
import { ConfigurationService, Oauth2ResourcesService } from '@common-modules';
import { IFundingResponse, IFundingResquest, IPropositionsCommerciale } from '@interfaces/funding.interface';
import { COFIDIS_METHODS, FUNDING_METHODS, MODE_FINANCEMENT_SERVICE } from '@interfaces/payment.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { FundingMethod } from '@models/payment-method';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FundingRepository {

    constructor(
        private readonly oauth2Ressource: Oauth2ResourcesService,
        private configService: ConfigurationService,
    ) {}

    public getFundingMethod(cartModel: MainCartModel): Observable<IFundingResponse> {
        return this.oauth2Ressource
            .ventes()
            .panier(cartModel.cartId)
            .modesFinancement()
            .useSalesApi()
            .get()
            .pipe(
                catchError(()=>of(null))
            );
    }

    public postFundingMethod(cartModel: MainCartModel, paymentType: string): Observable<IFundingResponse> {

        const params: IFundingResquest = {
            modeDeFinancement: MODE_FINANCEMENT_SERVICE[cartModel.fundingMethod.type],
            modeDePaiement: paymentType,
            urlDeRappel: this.configService.data_refconf.origin,

        };

        const phone = cartModel.getQuote().getProductByType(Phone);
        if (MODE_FINANCEMENT_SERVICE[cartModel.fundingMethod.type] === MODE_FINANCEMENT_SERVICE.EDP &&  phone) {
            params.parcours  =
            [
                {
                    idParcours: String(cartModel.getQuote().id),
                    produits: [
                        {
                            idProduit: String(cartModel.getQuote().getProductByType(Phone).id),
                            modeDeFinancement: 'EDP'
                        }
                    ]
                }
            ];
        }

        return this.oauth2Ressource
            .ventes()
            .panier(cartModel.cartId)
            .modesFinancement()
            .useSalesApi()
            .post(params);
    }

    public getFundingMethodInfo(
        funding: string,
        paymentMethods: string[],
        amount: number,
        propositionsCommerciale: IPropositionsCommerciale,
    ): Observable<FundingMethod>{
        const cofidisCode: number = COFIDIS_METHODS[funding];
        if (!cofidisCode) { // not cofidis method
            return of(new FundingMethod({
                coutTotalCredit: propositionsCommerciale?.coutTotalFinancement,
                dateFinancement: propositionsCommerciale?.dateFinancement,
                datePremiereEcheance: propositionsCommerciale?.premiereDateMensualite,
                derniereMensualite: propositionsCommerciale?.dernierMontantMensualite,
                mensualite: propositionsCommerciale?.montantMensualite,
                montantTotalDu: propositionsCommerciale?.montantAFinancer,
                nbEcheance: propositionsCommerciale?.nbrEcheances,
                taeg: propositionsCommerciale?.tAEG,
                type: funding,
                montant: amount,
                // montantTotalDu: amount,
                idOption: 0,
                label: FUNDING_METHODS[funding].label,
                paymentMethods
            }));
        }
    }
}

