import { Injectable } from '@angular/core';
import { ConfigurationService } from '@common-modules';
import { Plan, ProductFactory, Sensation } from '@bytel/bytel-sales';
import { DevisRepriseMobileIn, MOBILE_RECOVERY_STATUS } from '@interfaces/mobile-recovery-interface';
import { FeatureMobileRecoveryModel } from '@models/cart/feature-mobile-recovery.model';
import { MobileRecoveryModel, MobileRecoveryPartnerDataModel } from '@models/cart/mobile-recovery.model';
import { MobileRecoveryRepository } from '@repositories/mobile-recovery.repository';
import { isWithinInterval } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {EquipmentDetailsModel} from '@models/equipment-details.model';

@Injectable({
    providedIn: 'root'
})
export class MobileRecoveryService {
    public static MONTANT_MINIMUM_REPRISE = 10;

    public change$: Observable<MobileRecoveryModel>;
    public currentMobileRecovery: MobileRecoveryModel;

    private _changeSubject: BehaviorSubject<MobileRecoveryModel> = new BehaviorSubject(null);

    constructor(private readonly mobileRecoveryRepo: MobileRecoveryRepository,
                private readonly config: ConfigurationService) {
        this.currentMobileRecovery = this.mobileRecoveryRepo.getCurrentMobileRecovery();
        this._changeSubject.next(this.currentMobileRecovery);
        this.change$ = this._changeSubject.asObservable();
    }

    public getPartnerProducts(searchValue: string): Observable<MobileRecoveryModel[]> {
        return this.mobileRecoveryRepo.getPartnerProducts(searchValue);
    }

    public getPartnerProductFeatures(): Observable<FeatureMobileRecoveryModel[]> {
        return this.mobileRecoveryRepo.getPartnerProductFeatures(this.currentMobileRecovery.id).pipe(
            map((featuresMobileRecovery) => featuresMobileRecovery.map((featureMobileRecovery)=> {
                featureMobileRecovery.options = featureMobileRecovery.responses.map((res) => res.label);
                return featureMobileRecovery;
            }))
        );
    }

    public getQuotePartner(): Observable<void> {
        return this.mobileRecoveryRepo.getQuotePartner(this.currentMobileRecovery).pipe(
            map((data: DevisRepriseMobileIn) => {
                this.currentMobileRecovery.partnerData.insuranceAmount = Math.abs(data.livraison[0].montantAssurance);
                this.currentMobileRecovery.prices.base = data.montantEstimeReprise;
                this.currentMobileRecovery.prices.final = data.montantEstimeReprise;
                this.currentMobileRecovery.partnerData.quoteId = data.id;
                if (this.currentMobileRecovery.prices.final < MobileRecoveryService.MONTANT_MINIMUM_REPRISE) {
                    this.currentMobileRecovery.partnerData.status = MOBILE_RECOVERY_STATUS.UNSTARTED;
                }
                this.update(this.currentMobileRecovery);
            })
        );
    }

    public update(inputData: any): void{
        this.currentMobileRecovery = Object.assign(this.currentMobileRecovery, inputData);
        this._changeSubject.next(this.currentMobileRecovery);
        this.mobileRecoveryRepo.save(this.currentMobileRecovery);
    }

    public resetMobileRecovery(): void {
        const bonus = this.currentMobileRecovery.partnerData.bonus;
        this.mobileRecoveryRepo.delete();
        this.currentMobileRecovery = new MobileRecoveryModel({partnerData:new MobileRecoveryPartnerDataModel({bonus})});
        this._changeSubject.next(this.currentMobileRecovery);
    }

    public getBonus(product: EquipmentDetailsModel): number {
        let bonus: number = 0;
        const hasNoBonus: boolean = !product?.bonus_recovery || !product?.recovery_from_date || !product?.recovery_to_date;
        if (!hasNoBonus && isWithinInterval(new Date(), {start: new Date(product.recovery_from_date),
            end: new Date(product.recovery_to_date)})
            && Number(product.bonus_recovery) > 0) {
            bonus = Number(product.bonus_recovery);
        }
        this.currentMobileRecovery.partnerData.bonus = bonus;
        this.update(this.currentMobileRecovery);
        return this.currentMobileRecovery.partnerData.bonus;
    }

    public checkTypePlan(plan: Plan): void {
        if (!plan || !ProductFactory.Is(plan, Sensation, true)) {
            this.currentMobileRecovery.partnerData.bonus = 0;
            this.update(this.currentMobileRecovery);
        }
    }

    public getSalesMobileRecoveryData(): {idProduit: number; nom: string; marque: string} {
        this.currentMobileRecovery = this.mobileRecoveryRepo.getCurrentMobileRecovery() ?? this.currentMobileRecovery;
        return {
            idProduit: this.currentMobileRecovery.id,
            nom: this.currentMobileRecovery.name,
            marque: this.currentMobileRecovery.brand
        };
    }
}
