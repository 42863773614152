import { Model } from '@models/model';
import { SimsTypesModel } from './sim-type.model';
import { PortabilityPriceModel } from './portability-price.model';
import { Sim } from '@bytel/bytel-sales';

export class SimsByParcoursModel extends Model {

    public id: number;
    public sims: SimsTypesModel[];
    public portabilityPrices: PortabilityPriceModel[];
    public associatedSims: Sim[];

    constructor(data: Partial<SimsByParcoursModel>) {
        super(data);
    }

    public get preferedSim(): SimsTypesModel {
        return this.sims.find((sim: SimsTypesModel) => sim.isPrefered);
    }

    public setAssociatedSims(sims: Sim[]): void {
        this.associatedSims = sims;
    }
}
