export enum MOBILE_RECOVERY_STATUS {
    COMPLETED = 'completed',
    ONGOING = 'ongoing',
    UNFINISHED = 'unfinished',
    UNSTARTED = 'unstarted',
}

export enum RecoverySteps {
    START = 'start',
    HOMEPAGE = 'homepage',
    ESTIMATION = 'estimation',
    PAYMENT = 'payment',
}

export interface CritereRepriseParProduit {
    criteresRepriseProduit: CriteresRepriseProduit[];
    id: string;
    marque: string;
    nom: string;
    _links: Links2;

}

export interface CriteresRepriseProduit {
    id: string;
    libelle: string;
    reponses: ReponseCriteres[];
    options?: string[];
    selected?: string;
    descriptionSelected?: string[];
    optionSelected?: string;
}

export interface ReponseCriteres {
    description: string[];
    id: string;
    libelle: string;
}

export interface ResponseFeature{
    id: string;
    label: string;
    description: string[];
}


export interface  DevisRepriseMobileOut {
    criteresReprise: DevisRepriseMoobileOutCritereReprise[];
}

export interface DevisRepriseMoobileOutCritereReprise {
    id: string;
    question: string;
    reponse: {id: string; value: string};
}

export interface DevisRepriseMobileIn {
    id: string;
    livraison: DevisRepriseMobileInLivraison[];
    montantEstimeReprise: number;
    montantEstimeRepriseHT: number;
    tauxTVA: number;
}

export interface DevisRepriseMobileInLivraison {
    insurance?: boolean;
    modeLivraison: string;
    montantAssurance: number;
    montantLivraison: number;
}

export interface Links2 {
    evenementsFacturables: {href: string};
    self: {href: string};
    documents: {href: string};
    historiqueCommande: {href: string};
}

export interface PartnerProduct {
    id: number;
    marque: string;
    montantMax: number;
    montantMin: number;
    nom: string;
    _links: Links2;
}

export interface CountableResult<T> {
    resultCount: number;
    items: T[];
}
export interface RecoveryInput {
    recovery_iban?: string;
    recovery_insurance: number;
    recovery_imei: string;
    recovery_amount: number;
    recovery_bonus: number;
    recovery_product_id: string;
    recovery_quotation_id: string;
    recovery_name: string;
    recovery_brand: string;
    recovery_terms_of_sales: boolean;
}

export interface Terminal {
    id: string;
    name: string;
    brand: string;
}

export interface Survey {
    question: string;
    value: boolean;
}

export interface SalesPartnerMobileRecovery {
    id: string;
    terminal: Terminal;
    surveys: Survey[];
    hasInsurance: boolean;
}



