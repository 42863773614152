import { Model } from '@models/model';

export class PortaValidationModel extends Model {

    public status: string;
    public id?: string;
    public phoneNumber: string;
    public rioCode: string;

    constructor(data: Partial<PortaValidationModel>){
        super(data);
        this.phoneNumber = data?.phoneNumber?.replace('+33', '0');
    }

}

